import React, { useState } from 'react'
import { GridAudioButton } from './grid.styled'
import { sendEventVideoUnmute, sendEventVideoMute } from '../../utils/sendEvent'
import { getBrightcoveVideoId } from '../../utils/common'

const VideoAudioButton = ({ videoSrc, articleId, templateNumber }) => {
  const videoId = getBrightcoveVideoId(videoSrc)
  let video = document.querySelector(`video[data-video-id="${videoId}"]`)
  let player = null

  if (video) {
    player = window.videojs.getPlayer(video)
  }

  const [muted, setMuted] = useState(player ? player.muted() : true)

  const eventSend = () => {
    const eventData = {
      itemId: videoId,
      time: player.currentTime(),
      sourceId: articleId,
      sourceType: 'article'
    }

    if (muted) {
      sendEventVideoUnmute(eventData)
    } else {
      sendEventVideoMute(eventData)
    }
  }

  if (!videoId) {
    return null
  }

  return (
    <GridAudioButton
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        player.muted(!muted)
        setMuted(!muted)
        eventSend()
      }}
      className="audioButton"
      templateNumber={templateNumber}
    >
      <span
        className={muted ? 'vjs-icon-volume-mute' : 'vjs-icon-volume-high'}
      />
    </GridAudioButton>
  )
}

export default VideoAudioButton
