import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import loadable from '@loadable/component'
import queryString from 'query-string'
import { toastr } from 'react-redux-toastr'
import { useLocation } from '@reach/router'
import DragAndDrop from './DragAndDrop'
import { parseISO, differenceInMinutes } from 'date-fns'
import {
  GridLoader,
  GridContainer,
  Spinner,
  GridError,
  GridSearchScope
} from './grid.styled'
import { breakpoints, checkLandscape } from '../../constants/app'
import { getSize } from './helper'
import transformArticleData from '../../utils/transform-article-data'
import testMobile from '../../utils/testMobile'
import Card from './card'
import GetFingerprint from '../../utils/fingerprint'
import LatestNews from '../LatestNews'
import base from '../../sagas/base'
import isOutViewport from '../../utils/isOutViewport'
import useViewportSize from '../../utils/useViewportSize'
import updateEditorRowAndColumn from '../../utils/updateEditorRowAndColumn'
import MobileLiveTv from '../livetv/mobileLiveTv'
import Banner from '../banner'
import GridList from './gridList'

const Footer = loadable(() => import('../footer/footer'))

export const Grid = ({
  searchTerm,
  searchCategory,
  searchAuthor,
  language,
  mode,
  resetSearch = () => {},
  resetRecommendations = () => {},
  resetArticles = () => {},
  showVoting = false,
  ignoreLatestNews = false,
  categories,
  quickLinks,
  hideLiveTvMobile,
  adsManager,
  fetchAdsConfig,
  allowInfiniteScroll = true,
  subMode,
  shouldRenderLiveTvMobile = true,
  fetchArticleRecommendationsData,
  gridData,
  fetchCategoryData,
  fetchLiveTvData,
  fetchRecommendationsData,
  fetchSearchData,
  resetCategoryById = () => {},
  fetchTagData,
  resetTagById = () => {}
}) => {
  // can be used for XL, when defining column structure...
  const { width } = useViewportSize()
  const gridSize = getSize(width)
  const loc = useLocation()
  const [searchingArticleTitle, setSearchingArticleTitle] = useState('')
  const [searchingArticleId, setSearchingArticleId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState([])
  const [noMatchingArticles, setNoMatchingArticles] = useState(false)
  const [deleteArticleIds, setDeleteArticleIds] = useState([])
  const [isPublishing, setIsPublishing] = useState(false)
  const [videoPlayingIdMobile, setVideoPlayingId] = useState()

  const isLandScape = checkLandscape()

  // Fetch Ad config first
  useEffect(() => {
    fetchAdsConfig()
  }, [])

  let gridResults = gridData

  if (mode === 'rearrange') {
    gridResults.data = gridResults.data.filter(
      (e) =>
        e.label == null &&
        !e.isVodArticles &&
        !e.isBusinessArticles &&
        !e.isSportsArticle
    )
  }

  const onSetDeleteArticleIds = (val) => {
    setDeleteArticleIds(val)
  }

  useEffect(() => {
    let interval

    interval = setInterval(() => {
      if (mode === 'homepage' || mode === 'rearrange') {
        const { lastUpdate } = gridData
        const diff = differenceInMinutes(new Date(), parseISO(lastUpdate))
        if (diff >= 20) {
          resetArticles()
          fetchData(0, true)
        }
      }
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [mode, gridData.lastUpdate])

  useEffect(() => {
    resetArticles()
  }, [mode])

  useEffect(() => {
    const updatedGridSize = getSize(width)
    // if video is playing in full screen don't reload the page
    if (testMobile() && typeof videojs !== 'undefined') {
      Object.keys(videojs.players).map((plyr) => {
        const player = videojs.players[plyr]
        if (player && player.isFullscreen && player.isFullscreen()) {
          return false
        }
      })
    }

    if (mode === 'homepage' || mode === 'rearrange') {
      if (gridData.gridSize !== updatedGridSize) {
        if (gridData.data.length > 0) {
          resetArticles()
          fetchData(0)
        }

        if (showVoting && updatedGridSize === 's') {
          initializeVote()
        }
      }
    } else {
      if (
        gridResults.data.length > 0 &&
        gridResults.gridSize !== updatedGridSize
      ) {
        resetSearch()
        resetCategoryById()

        // reset tag
        resetTagById()

        // reset article recommendation
        resetRecommendations()

        fetchData(0)
      }
    }
  }, [gridSize, width])

  useEffect(() => {
    if (searchTerm) {
      resetSearch()
      fetchSearchData({
        page: 0,
        searchTerm,
        searchCategory: null,
        searchAuthor: null,
        gridSize: getSize(width)
      })
    }
  }, [searchTerm])

  useEffect(() => {
    if (searchCategory) {
      resetSearch()
      fetchSearchData({
        page: 0,
        searchTerm: null,
        searchCategory,
        searchAuthor: null,
        gridSize: getSize(width)
      })
    }
  }, [searchCategory])

  useEffect(() => {
    if (searchAuthor) {
      resetSearch()
      fetchSearchData({
        page: 0,
        searchTerm: null,
        searchCategory: null,
        searchAuthor,
        gridSize: getSize(width)
      })
    }
  }, [searchAuthor])

  const initializeVote = () => {
    var MegaphoneController = document.getElementById(
      'MegaControllerIframe-1590050011560'
    )

    if (MegaphoneController) {
      MegaphoneController.receiveMessage = function (message) {
        if (message.data.origin === 'Megaphone') {
        }
      }
      window.addEventListener(
        'message',
        MegaphoneController.receiveMessage,
        false
      )
    } else {
      setTimeout(() => initializeVote(), 100)
    }
  }

  const fetchData = (page = gridResults.page, refresh = false) => {
    if (searchCategory === 'trending' && page !== 0) {
      return
    }

    if (mode === 'search') {
      if (searchCategory) {
        fetchSearchData({
          page,
          searchTerm: null,
          searchCategory,
          searchAuthor: null,
          gridSize: getSize(width)
        })
      } else if (searchAuthor) {
        fetchSearchData({
          page,
          searchTerm: null,
          searchCategory: null,
          searchAuthor,
          gridSize: getSize(width)
        })
      } else if (searchTerm) {
        fetchSearchData({
          page,
          searchTerm,
          searchCategory: null,
          searchAuthor: null,
          gridSize: getSize(width)
        })
      }
    } else if (mode === 'article') {
      fetchArticleRecommendationsData({ page, gridSize: getSize(width) })
    } else if (mode === 'livetv') {
      fetchLiveTvData({ page, gridSize: getSize(width) })
    } else if (mode === 'category') {
      fetchCategoryData({ page, gridSize: getSize(width) })
    } else if (mode === 'tag') {
      fetchTagData({ page, gridSize: getSize(width) })
    } else {
      fetchRecommendationsData({ page, refresh, gridSize: getSize(width) })
    }
  }

  const onScroll = () => {
    if (gridSize === 's') {
      try {
        const videos = Array.from(document.querySelectorAll('video'))
        let isPlaying = false

        videos.forEach((video) => {
          const videoId = video.getAttribute('data-video-id')
          const player = window.videojs ? window.videojs.getPlayer(video) : null
          if (player) {
            let shouldStop =
              isPlaying || isOutViewport(video, { top: 85, bottom: 50 }).any

            if (shouldStop && !player.paused()) {
              player.pause()
            }

            if (!shouldStop && gridSize === 's') {
              isPlaying = true
              setVideoPlayingId(videoId)
            }

            if (!shouldStop && player.paused()) {
              player.play()
              setVideoPlayingId(videoId)
            }
          }
        })
      } catch (error) {
        console.log('Error while autoplaying the video', error)
      }
    }
    // hide all empty ads cell
    if (isLandScape) {
      const ele = document.querySelectorAll('.inlineAdCardEmptyCell')
      ;[...ele].map((el) => {
        if (
          el &&
          el.parentNode &&
          el.parentNode.getAttribute('data-ad-container')
        ) {
          el.style.display = 'none'
          el.parentNode.style.display = 'none'
        }
      })
    } else {
      const ele = document.querySelectorAll('.inlineAdCard')
      ;[...ele].map((el) => {
        if (
          el &&
          el.children &&
          el.children.length &&
          el.children[0].classList.contains('inlineAdCardEmptyCell')
        ) {
          el.style.display = 'none'
          el.nextElementSibling.classList.remove('ad-fallback-cell')
        }
      })
    }
  }

  GetFingerprint()

  if (
    gridResults.data &&
    !gridResults.data.length &&
    !gridResults.fetching &&
    !gridResults.hasMore
  ) {
    return <GridError>لايوجد معروضات</GridError>
  }

  if (gridResults.data && gridResults.data.length) {
    let hasVoteInside, rowsData

    rowsData = gridResults.data[0].isCategory
      ? gridResults.data[0].data
      : gridResults.data[0]
    hasVoteInside = rowsData.find(({ vote }) => vote)

    if (showVoting && !hasVoteInside) {
      rowsData = rowsData.filter(({ latestNews }) => !latestNews)

      if (gridResults.data[0].isCategory) {
        gridResults.data[0].data = rowsData
      } else {
        gridResults.data[0] = rowsData
      }

      const dataToUnshift = [{ vote: true, uiColumns: 1 }]

      if (gridSize !== 's') {
        dataToUnshift.push({ latestNews: true })
      }

      gridResults.data.unshift(dataToUnshift)
    } else if (!showVoting && hasVoteInside) {
      gridResults.data.shift()

      if (gridSize !== 's') {
        if (gridResults.data[0].isCategory) {
          gridResults.data[0].data.push({ latestNews: true })
        } else {
          gridResults.data[0].push({ latestNews: true })
        }
      }
    }
  }

  const getCategoryUrl = (row) => {
    const { data = [] } = categories
    let categorySlug = row.linkTo

    if (row.isTrending) {
      return row.linkTo
    }

    const category = data.find((categoryData) => {
      const categoryLabel =
        language === 'en' ? categoryData.en : categoryData.ar
      if (categoryLabel === row.label) {
        return true
      }
    })

    if (category) {
      categorySlug = category.slug
    }

    return categorySlug
  }

  const searchByTitle = async (terms) => {
    try {
      setIsLoading(true)
      setNoMatchingArticles(false)
      const params = { page: 1, size: 6, q: terms }
      const res = await base.get('/search', params)
      if (res.data.data.length > 0) {
        setSearchResults(
          res.data.data
            .filter((e) => e.article_type !== 'sub-live')
            .map((e) =>
              transformArticleData({
                article: e
              })
            )
        )
      } else {
        setSearchResults([])
        setNoMatchingArticles(true)
      }
    } catch (ex) {
      setSearchResults([])
    } finally {
      setIsLoading(false)
    }
  }

  const searchById = async (id) => {
    try {
      setIsLoading(true)
      setNoMatchingArticles(false)
      const params = { id }
      const res = await base.get('/content', params)
      if (
        res.data &&
        res.data.data &&
        res.data.data.article_type !== 'sub-live'
      ) {
        const arr = []
        arr.push(res.data.data)
        setSearchResults(
          arr.map((e) =>
            transformArticleData({
              article: e
            })
          )
        )
      } else {
        setSearchResults([])
        setNoMatchingArticles(true)
      }
    } catch (ex) {
      setSearchResults([])
    } finally {
      setIsLoading(false)
    }
  }

  if (isPublishing) {
    return (
      <GridLoader>
        <Spinner />
      </GridLoader>
    )
  }

  return (
    <GridContainer>
      <div>
        {!ignoreLatestNews &&
          (mode === 'homepage' ||
            mode === 'rearrange' ||
            mode === 'category' ||
            mode === 'tag' ||
            (mode === 'search' && searchCategory)) &&
          (isLandScape || width <= breakpoints.m) && (
            <div id="latestNewsWrapper" style={{ margin: '10px 0 30px 0' }}>
              <div style={{ margin: '-2px -10px 0px -10px' }}>
                <LatestNews
                  onDropLeft={(item) => {}}
                  isRearrange={mode === 'rearrange'}
                  type="mobile"
                />
              </div>
            </div>
          )}
      </div>

      {quickLinks.showLiveTvMobileInGrid && shouldRenderLiveTvMobile && (
        <div
          style={{
            margin: '-30px -10px 10px -10px'
          }}
        >
          <MobileLiveTv
            hideLiveTvMobile={hideLiveTvMobile}
            isAsharqAppPopupVisible={quickLinks.isAsharqAppPopupVisible}
          />
        </div>
      )}
      <div>
        {mode === 'homepage' && width <= breakpoints.m && (
          <div
            style={{
              paddingTop: quickLinks.showLiveTvMobileInGrid ? '20px' : 0
            }}
          >
            <Banner page="homepage" />
          </div>
        )}
      </div>

      {searchAuthor && (
        <GridSearchScope>
          <p>{searchAuthor}</p>
        </GridSearchScope>
      )}
      {searchTerm && (
        <GridSearchScope>
          <p>{searchTerm}</p>
        </GridSearchScope>
      )}
      {gridResults.error && (
        <GridError>
          Unexpected Error - Please reload this page to continue
        </GridError>
      )}

      {mode === 'rearrange' && (
        <div style={{ minHeight: 200, display: 'flex', marginBottom: 40 }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', marginLeft: 40 }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <input
                onChange={(e) => setSearchingArticleTitle(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchByTitle(searchingArticleTitle)
                  }
                }}
                type="text"
                placeholder="search"
              />
              <button onClick={() => searchByTitle(searchingArticleTitle)}>
                Search By Title
              </button>
            </div>
            <div
              style={{
                marginTop: 20,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <input
                onChange={(e) => setSearchingArticleId(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    searchById(searchingArticleId)
                  }
                }}
                type="text"
                placeholder="search"
              />
              <button
                onClick={() => {
                  searchById(searchingArticleId)
                }}
              >
                Search By Article ID
              </button>
            </div>
            <button
              style={{ marginTop: 20 }}
              onClick={async () => {
                try {
                  const itemNumber = gridResults.data
                    .map((e) => e.data.length)
                    .reduce((a, b) => a + b, 0)

                  if (itemNumber <= 1) {
                    toastr.error("You can't publish, if there is no article")
                    return
                  }

                  setIsPublishing(true)

                  const token = queryString.parse(loc.search).accessToken
                  await updateEditorRowAndColumn(
                    deleteArticleIds,
                    gridResults.data,
                    token
                  )
                  toastr.success('Publish successfull')
                } finally {
                  setIsPublishing(false)
                }
              }}
            >
              Publish
            </button>
          </div>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            {isLoading && <Spinner />}

            {noMatchingArticles && <p>There are no matching articles.</p>}

            {!isLoading &&
              !noMatchingArticles &&
              searchResults &&
              searchResults.map((item, idx) => {
                return (
                  <Card
                    hideDrop
                    hideDelete
                    rowIndex={1}
                    rowLength={1}
                    isLatestNews={false}
                    rowHasVote={false}
                    isVote={false}
                    article={item}
                    newWidth={280}
                    newHeight={200}
                    newMarginBottom={16}
                    newMarginLeft={16}
                    index={1}
                    language={language}
                    key={`card_${idx}`}
                    id={`card_${idx}`}
                    page="homepage"
                    isRearrange={mode === 'rearrange'}
                    initializeVote={initializeVote}
                  />
                )
              })}
          </div>
        </div>
      )}

      {adsManager.fetched ? (
        <GridList
          key="gridList"
          mode={mode}
          language={language}
          onScroll={onScroll}
          gridResults={gridResults}
          getCategoryUrl={getCategoryUrl}
          fetchData={fetchData}
          initializeVote={initializeVote}
          onSetDeleteArticleIds={onSetDeleteArticleIds}
          deleteArticleIds={deleteArticleIds}
          videoPlayingIdMobile={videoPlayingIdMobile}
          location={loc}
          adsManager={adsManager}
          allowInfiniteScroll={allowInfiniteScroll}
          subMode={subMode}
        />
      ) : null}

      {mode === 'homepage' ? (
        <div style={{ margin: '-40px 0 60px 0' }}>
          {searchCategory === 'trending' && gridResults.data.length > 0 && (
            <Footer />
          )}
        </div>
      ) : null}
    </GridContainer>
  )
}

const mapStateToProps = ({ categories, quickLinks, adsManager }) => {
  return {
    categories,
    quickLinks,
    adsManager
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitScrollEvents: (events) => {
      const payload = { events }
      dispatch({ type: 'SUBMIT_SCROLL_EVENTS_REQUESTED', payload })
    },
    hideLiveTvMobile: () => {
      dispatch({ type: 'SHOW_HIDE_LIVE_TV_MOBILE', payload: false })
    },
    fetchAdsConfig: () => {
      dispatch({ type: 'ADS_CONFIG_REQUEST' })
    }
  }
}

const GridBase = (props) => (
  <>
    <DragAndDrop {...props} />
  </>
)

export default connect(mapStateToProps, mapDispatchToProps)(GridBase)
