import React from 'react'
import isEqual from 'lodash/isEqual'
import LazyLoad from 'react-lazyload'
import DateTimeService from '../../utils/common'

import redArrow from '../menu/redArrow.svg'
import whiteArrow from '../menu/whiteArrow.svg'

import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))

import {
  ArticlesContainer,
  ArticlesCardRow,
  ArticleCard,
  ArticleCardDetailContainer,
  ArticleCardLabel,
  CategoryLabelImg,
  ArticleCardTitle,
  ArticleCardDate
} from './styles'

export default class AsharqBusinessComponent extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps)
  }
  render() {
    const { data = [], isMobile, label, isRearrange } = this.props

    if (isRearrange) {
      return null
    }

    return (
      <LazyLoad height={'100%'} offset={isMobile ? 300 : 500}>
        <ArticlesContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ArticleCardLabel>
              <a
                rel="noopener noreferrer nofollow"
                target="_blank"
                href="https://asharqbusiness.com/"
              >
                <div>{label}</div>
                <CategoryLabelImg
                  className="whiteArrow"
                  id="arrow"
                  src={whiteArrow}
                  alt="labelArrow"
                />
                <CategoryLabelImg
                  className="redArrow"
                  id="arrow"
                  src={redArrow}
                  alt="labelArrow"
                />
              </a>
            </ArticleCardLabel>
            <ArticlesCardRow>
              {data.map((article, index) => (
                <ArticleCard
                  key={`business-article-card-${index}`}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  href={`https://asharqbusiness.com/article/${article.id}`}
                  noOfItems={data.length}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex'
                    }}
                  >
                    <img
                      src={
                        isMobile
                          ? article.mainImage.imagesUrls.small
                          : article.mainImage.imagesUrls.med
                      }
                      alt="business article image"
                      loading="lazy"
                    />
                  </div>
                  <ArticleCardDetailContainer>
                    <ArticleCardTitle>
                      <Ellipsis lines={4} text={article.title} />
                    </ArticleCardTitle>
                    {article.publishedAt && (
                      <ArticleCardDate>
                        <time
                          dateTime={DateTimeService.formattedDateTime(
                            article.publishedAt
                          )}
                        >
                          {DateTimeService.formatInLocalTime(
                            article.publishedAt
                          )}
                        </time>
                      </ArticleCardDate>
                    )}
                  </ArticleCardDetailContainer>
                </ArticleCard>
              ))}
            </ArticlesCardRow>
          </div>
        </ArticlesContainer>
      </LazyLoad>
    )
  }
}
