export const STAGING_URL = {
  BANNER_DESKTOP:
    'https://img-staging.asharq.com/30d4b3a4-af8b-4049-80b5-af631c9928f7.jpg',
  BANNER_MOBILE:
    'https://img-staging.asharq.com/d88f0f37-ae71-4134-92a1-99b7f4a9062f.jpg',
  HOME_BANNER_DESKTOP:
    'https://img-staging.asharq.com/db9bd006-da47-4ed8-875f-537f39545f95.jpg',
  HOME_BANNER_MOBILE:
    'https://img-staging.asharq.com/9aee6d01-b2ad-4bc7-a4c7-a73938b60273.jpg'
}

export const PRODUCTION_URL = {
  BANNER_DESKTOP:
    'https://img.asharq.com/4150af8f-b27d-43de-9a30-bd79753bfe03.jpg',
  BANNER_MOBILE:
    'https://img.asharq.com/34f9ec99-960d-402a-b6a5-1685b864a4c5.jpg',
  HOME_BANNER_DESKTOP:
    'https://img.asharq.com/19e21785-e7eb-47c6-8a19-514daf3f6631.jpg',
  HOME_BANNER_MOBILE:
    'https://img.asharq.com/4d4ed84f-a61e-4856-89f3-54ddaa159bde.jpg'
}
