import React, { useState, useEffect } from 'react'

import { GridTimeBox } from './grid.styled'

import { getBrightcoveVideoId } from '../../utils/common'

const VideoTimeButton = ({ videoSrc }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoId = getBrightcoveVideoId(videoSrc)

  let video = document.querySelector(`video[data-video-id="${videoId}"]`)
  let player = null

  if (video) {
    player = window.videojs.getPlayer(video)
  }

  if (player == null) {
    return null
  }

  video.onloadedmetadata = () => {
    setIsLoaded(true)
  }

  player.on('play', function () {
    setIsPlaying(true)
  })

  player.on('pause', function () {
    setIsPlaying(false)
  })

  if (!isLoaded || isPlaying) {
    return null
  }

  let min = ('0' + Math.round(player.duration() / 60)).slice(-2)
  let sec = ('0' + Math.round(player.duration() % 60)).slice(-2)

  return <GridTimeBox id="timebox">{`${min}:${sec}`}</GridTimeBox>
}

export default VideoTimeButton
