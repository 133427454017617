import { Link } from 'gatsby'
import React, { useState, useRef, useEffect } from 'react'
import isTouchDevice from 'is-touch-device'
import ChopLines from 'chop-lines'

import {
  TemplateCardBackground,
  GridCardLink,
  TextContainer,
  SpaceBetweenTitleAndDescription
} from '../grid.styled'
import BackgroundCover from '../../background/background'
import SocialControls from '../../socialcontrols/socialcontrols'
import VideoAudioButton from '../VideoAudioButton'
import VideoTimeButton from '../VideoTimeButton'
import LiveIcon from '../../liveIcon'

import { calculateDescriptionHeight } from '../helper'

import { Title, Description } from '../Ellipsis'

const TemplateCard4 = ({
  preview,
  index,
  article,
  setLoadedVideo,
  loadedVideo,
  cardType,
  textOutside,
  isRearrange,
  language,
  shareVisible,
  gridSize,
  videoPlayingIdMobile,
  isLarge,
  gridRow,
  gridColumn,
  type,
  itemType,
  cardSize,
  subMode
}) => {
  const bodyRef = useRef(null)
  const textContainerRef = useRef(null)
  const [state, setState] = useState({
    bodyHeight: 0,
    lines: 0
  })
  const [isHover, setIsHover] = useState(false)
  const titleRef = useRef(null)
  const spaceBetweenTitleAndDescRef = useRef(null)
  const spaceAfterDescRef = useRef(null)

  const BODY_LINE_HEIGHT = 26

  /*const calculateTitleRows = () => {
    const node = textContainerRef.current

    if (node) {
      const paddingTop = parseInt(getComputedStyle(node).paddingTop)
      const paddingBottom = parseInt(getComputedStyle(node).paddingBottom)
      const height = node.offsetHeight - paddingTop - paddingBottom
      const rows = Math.floor(height / 28)

      return rows
    }

    return 0
  }*/

  useEffect(() => {
    setTimeout(() => {
      const descHeight = calculateDescriptionHeight(
        textContainerRef,
        titleRef,
        spaceAfterDescRef,
        spaceBetweenTitleAndDescRef,
        state,
        BODY_LINE_HEIGHT
      )
      if (descHeight) {
        setState(descHeight)
      }
    }, 100)
  }, [bodyRef])

  const getTitleMaxLines = () => {
    if (article.trimTitle) {
      return 2
    }
    if (cardSize && cardSize === '1/3') {
      return 5
    } else if (cardSize && cardSize === '1/2') {
      return 6
    }

    return 3
  }

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <TemplateCardBackground flexDirection="column">
        <BackgroundCover
          preview={preview}
          isHover={isHover}
          index={index}
          src={article.mainImageUrl}
          imageDpr={1}
          imageQuality={60}
          imageMeta={article.mainImage}
          videoSrc={article.mainVideoUrl}
          setLoadedVideo={setLoadedVideo}
          cardType={cardType}
          disableControls={true}
          loopVideo={true}
          autoplay={article.autoPlayGridVideo}
          backgroundHeight={'50%'}
          title={article.title}
          lazyload={true}
          noBorderRadius={true}
          templateNumber={type}
          articleId={article.id}
        />

        <TextContainer ref={textContainerRef} f1>
          <div ref={titleRef}>
            <Title text={article.title} lines={getTitleMaxLines()} />
          </div>

          <SpaceBetweenTitleAndDescription
            ref={spaceBetweenTitleAndDescRef}
            style={{
              minHeight: '10%'
            }}
          />

          {article.cardSummary ? (
            <div
              ref={bodyRef}
              style={{
                height: `${state.bodyHeight}px`,
                fontSize: '15px',
                color: 'rgb(132, 132, 132)',
                fontFamily: 'Neue Haas Grotesk Bloomberg',
                lineHeight: '26px',
                textAlign: 'right'
              }}
              data-lines={state.lines}
            >
              {state.lines > 0 && (
                <ChopLines
                  lines={state.lines}
                  lineHeight={26}
                  ellipsis={<span />}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.cardSummary
                    }}
                  />
                </ChopLines>
              )}
            </div>
          ) : (
            <div
              ref={bodyRef}
              style={{
                height: `${state.bodyHeight}px`,
                background: 'white'
              }}
            >
              {state.lines > 0 && (
                <Description
                  text={article.contentAr}
                  fontSize={'15px'}
                  lines={state.lines}
                />
              )}
            </div>
          )}
          <div
            ref={spaceAfterDescRef}
            style={{
              minHeight: '15%'
            }}
          />
        </TextContainer>
      </TemplateCardBackground>

      <GridCardLink isRearrange={isRearrange}>
        {isRearrange ? null : (
          <Link
            to={
              article.isShortHandArticle
                ? `/specials/${article.slug}`
                : `/${language}/${article.slug}`
            }
            state={{ fromGrid: true }}
          >
            {article.isLiveArticle && (
              <div
                style={{
                  position: 'absolute',
                  top: 17,
                  left: 10,
                  zIndex: 999
                }}
              >
                <LiveIcon label={'تغطية مستمرة'} />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                zIndex: 999
              }}
            >
              <SocialControls
                itemType={itemType}
                shareUri={
                  article.isShortHandArticle
                    ? `/specials/${article.slug}`
                    : `/${language}/${article.slug}`
                }
                visible={isHover || isTouchDevice()}
                theme="light"
                article={article}
                hideOnMobile={true}
                subMode={subMode}
              />
            </div>
            {article.mainVideoUrl && loadedVideo && (
              <VideoAudioButton
                articleId={article.id}
                visible={shareVisible}
                videoSrc={article.mainVideoUrl}
                templateNumber={type}
              />
            )}
            {article.mainVideoUrl && loadedVideo && (
              <VideoTimeButton videoSrc={article.mainVideoUrl} />
            )}
          </Link>
        )}
      </GridCardLink>
    </div>
  )
}

export default TemplateCard4
