import React from 'react'
import { Title } from './Ellipsis'

const VideoGridTitle = ({ text, titleLines = 3, isHover }) => {
  if (!isHover) {
    return null
  }

  return <Title shadow color="white" text={text} lines={titleLines} />
}

export default VideoGridTitle
