import { Link } from 'gatsby'
import React, { useState } from 'react'
import isTouchDevice from 'is-touch-device'

import {
  GridBackground,
  GridCardContent,
  GridCardLink,
  GridItemContent,
  GridItemContentContainer,
  GridOutsideTitle
} from '../grid.styled'
import BackgroundCover from '../../background/background'
import SocialControls from '../../socialcontrols/socialcontrols'
import VideoAudioButton from '../VideoAudioButton'
import VideoGridTitle from '../VideoGridTitle'
import VideoTimeButton from '../VideoTimeButton'
import { Title } from '../Ellipsis'
import LiveIcon from '../../liveIcon'

const GridTitle = ({
  article,
  gridSize,
  videoPlayingIdMobile,
  textOutside,
  isHover,
  isLarge,
  titleLines
}) => {
  if (textOutside) {
    return null
  }

  if (article.mainVideoUrl) {
    if (article.showTitle === false) {
      return null
    }

    return (
      <VideoGridTitle
        text={article.title}
        isLarge={isLarge}
        titleLines={titleLines}
        isHover={isHover}
      />
    )
  }

  return <Title shadow color="white" text={article.title} lines={titleLines} />
}

const TemplateCard1 = ({
  preview,
  index,
  article,
  setLoadedVideo,
  loadedVideo,
  cardType,
  textOutside,
  isRearrange,
  language,
  shareVisible,
  gridSize,
  videoPlayingIdMobile,
  isLarge,
  type,
  itemType,
  cardSize,
  subMode
}) => {
  const [isHover, setIsHover] = useState(false)

  const getTitleMaxLines = () => {
    if (article.trimTitle) {
      if (cardSize && cardSize === '1/4') {
        return 2
      }
      return 3
    }

    if (cardSize) {
      if (cardSize === '1/2') {
        return 5
      } else if (cardSize === '1/3') {
        return 6
      } else {
        return 4
      }
    }

    return 3
  }

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <GridBackground>
        <BackgroundCover
          preview={preview}
          isHover={isHover}
          index={index}
          src={article.mainImageUrl}
          imageDpr={1}
          imageQuality={60}
          imageMeta={article.mainImage}
          videoSrc={article.mainVideoUrl}
          setLoadedVideo={setLoadedVideo}
          cardType={cardType}
          disableControls={true}
          loopVideo={true}
          autoplay={article.autoPlayGridVideo}
          backgroundHeight={textOutside ? '60%' : '100%'}
          title={article.title}
          lazyload={true}
          articleId={article.id}
        />
        {textOutside && (
          <GridOutsideTitle>
            <span>{article.title}</span>
          </GridOutsideTitle>
        )}
      </GridBackground>
      <GridCardLink isRearrange={isRearrange}>
        {isRearrange ? null : (
          <Link
            to={
              article.isShortHandArticle
                ? `/specials/${article.slug}`
                : `/${language}/${article.slug}`
            }
            state={{ fromGrid: true }}
          >
            {article.isLiveArticle && (
              <div
                style={{
                  position: 'absolute',
                  top: 17,
                  left: 10,
                  zIndex: 999
                }}
              >
                <LiveIcon label={'تغطية مستمرة'} />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                zIndex: 999
              }}
            >
              <SocialControls
                itemType={itemType}
                shareUri={
                  article.isShortHandArticle
                    ? `/specials/${article.slug}`
                    : `/${language}/${article.slug}`
                }
                visible={isHover || isTouchDevice()}
                theme="light"
                article={article}
                hideOnMobile={true}
                subMode={subMode}
              />
            </div>
            {article.mainVideoUrl && loadedVideo && (
              <VideoAudioButton
                articleId={article.id}
                visible={shareVisible}
                videoSrc={article.mainVideoUrl}
                templateNumber={type}
              />
            )}
            {article.mainVideoUrl && loadedVideo && (
              <VideoTimeButton videoSrc={article.mainVideoUrl} />
            )}
          </Link>
        )}
      </GridCardLink>
      <GridCardContent>
        <GridItemContentContainer>
          <GridItemContent>
            <GridTitle
              article={article}
              gridSize={gridSize}
              videoPlayingIdMobile={videoPlayingIdMobile}
              textOutside={textOutside}
              isHover={isHover}
              isLarge={isLarge}
              titleLines={getTitleMaxLines()}
            />
          </GridItemContent>
        </GridItemContentContainer>
      </GridCardContent>
    </div>
  )
}

export default TemplateCard1
