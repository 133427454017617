import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const VODContainer = styled.div`
  display: flex;
  background-color: #101010;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: 65px;
  margin-top: -50px;
`
export const VODInnerContainer = styled.div`
  max-width: 1300px;
  margin: 30px auto;
  width: 100%;
  padding: 0 10px 10px 10px;
  @media ${device.s} {
    padding: 0px 10px 10px;
  }
`

export const VODTitle = styled.a`
  width: 100%;
  font-size: 28px;
  color: #ffffff;
  font-weight: bold;
  font-family: 'Neue Haas Grotesk Bloomberg';
  padding-right: 10px;
  text-decoration: none;
  cursor: pointer;

  @media ${device.s} {
    font-size: 24px;
  }
`
export const VODCardRow = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: row;

  a:last-child {
    margin-left: 0;
  }

  @media ${device.s} {
    flex-direction: column;
    a:last-child {
      margin: 0;
    }
  }
`
export const VODCard = styled.a`
  width: ${({ noOfItems }) => (noOfItems ? 100 / noOfItems : 25)}%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  img {
    height: 182px;
    width: 100%;
    border-radius: 0 20px 0 0;
  }

  @media ${device.s} {
    width: 100%;
    margin: 0 0 20px;

    img {
      height: 100%;
    }
  }
`

export const VODCardDetailContainer = styled.div`
  height: 135px;
  background-color: #232d2f;
  font-family: 'Neue Haas Grotesk Bloomberg';
  padding: 15px 15px;
  border-radius: 0 0 0 20px;
  line-height: 160%;

  @media ${device.s} {
    max-height: 135px;
    height: auto;
  }
`
export const VODCardDetail = styled.div`
  display: flex;
  flex-direction: row;
`
export const VODDate = styled.div`
  font-size: 15px;
  color: #ffffff;
  font-weight: 400;

  @media ${device.s} {
    font-size: 18px;
  }
`
export const VODCardTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  padding-top: 10px;
`
export const VODVerticalLine = styled.div`
  width: ${({ width }) => (width ? width : '4px')};
  height: ${({ height }) => (height ? height : ' 63px')};
  background-color: #c62426;
  position: absolute;

  @media ${device.s} {
    width: 5px;
    height: 60px;
  }
`
export const VODCardDuration = styled.span`
  background-color: #101010;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Neue Haas Grotesk Bloomberg';
  width: 57px;
  height: 24px;
  border-radius: 0 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 10px;
  left: 8px;
`
