import { useEffect, useState } from "react"

export const SCROLL_UP = "up"
export const SCROLL_DOWN = "down"

export const useScrollDirection = ({
  initialDirection = SCROLL_DOWN,
  thresholdPixels = 64,
  scrollWithData = false
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection)
  const [scrollData, setScrollData] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0
  })

  useEffect(() => {
    const threshold = thresholdPixels || 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false
        return
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP)
      setScrollData((last) => {
        return {
          x: window.scrollX,
          y: window.scrollY,
          lastX: last.x,
          lastY: last.y
        }
      })
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [initialDirection, thresholdPixels])

  return scrollWithData ? scrollData : scrollDir
}
