import axios from 'axios'

const API = process.env.GATSBY_API_URL || null

const update = async (articles, token) => {
  let headers = {}
  headers['Authorization'] = `Bearer ${token}`
  const res = await axios.post(
    API + '/admin/articles/rearrange',
    { articles },
    { headers }
  )
  return res
}

const updateEditorRowAndColumn = async (deleteArticleIds, articles, token) => {
  const updateArticles = []

  // delete
  for (let id of deleteArticleIds) {
    updateArticles.push({ item_id: id, editorRow: 0, editorColumn: 0 })
  }

  for (let i = 0; i < articles.length; i++) {
    const row = articles[i]
    const rowIndex = i + 1
    for (let j = 0; j < row.data.length; j++) {
      const article = row.data[j]
      const columnIndex = j + 1
      if (article.latestNews) continue

      updateArticles.push({
        item_id: article.id,
        editorRow: parseInt(rowIndex),
        editorColumn: parseInt(columnIndex)
      })
    }
  }

  await update(updateArticles, token)
}

export default updateEditorRowAndColumn
