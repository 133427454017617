import React from 'react'
import LazyLoad from 'react-lazyload'
import isEqual from 'lodash/isEqual'
import { TemplateCard } from './TemplateCard'

export default class LazyLoadTemplateCard extends React.Component {
  shouldComponentUpdate(nextProps) {
    const shouldUpdate = !isEqual(this.props, nextProps)
    return shouldUpdate
  }
  render() {
    const { shouldLoadLazily = true, isMobile, newHeight } = this.props

    // for not lazy loading the first row of the grid to avoid empty space shown to user
    if (!shouldLoadLazily) {
      return <TemplateCard {...this.props} />
    }

    return (
      <LazyLoad height={newHeight || '100%'} offset={isMobile ? 300 : 500}>
        <TemplateCard {...this.props} />
      </LazyLoad>
    )
  }
}
