import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import isTouchDevice from 'is-touch-device'

import {
  GridMobileBackground,
  GridCardLink,
  TextContainer
} from '../grid.styled'
import BackgroundCover from '../../background/background'
import SocialControls from '../../socialcontrols/socialcontrols'
import VideoAudioButton from '../VideoAudioButton'
import { Title } from '../Ellipsis'
import LiveIcon from '../../liveIcon'

const customStyle = {
  borderRadius: '0 14px 0 0'
}

const MobileTemplateCard = ({
  preview,
  index,
  article,
  setLoadedVideo,
  loadedVideo,
  cardType,
  isRearrange,
  language,
  shareVisible,
  type,
  itemType,
  subMode
}) => {
  const textContainerRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  return (
    <div
      onTouchStart={() => setIsHover(true)}
      onTouchEnd={() => setIsHover(false)}
    >
      <GridMobileBackground>
        <BackgroundCover
          preview={preview}
          isHover={isHover}
          index={index}
          src={article.mainImageUrl}
          imageDpr={1}
          imageQuality={60}
          imageMeta={article.mainImage}
          videoSrc={article.mainVideoUrl}
          setLoadedVideo={setLoadedVideo}
          cardType={cardType}
          disableControls={true}
          loopVideo={true}
          autoplay={article.autoPlayGridVideo}
          backgroundHeight={'100%'}
          title={article.title}
          lazyload={true}
          articleId={article.id}
          style={customStyle}
          templateNumber={6}
        />
        <TextContainer
          style={{
            padding: '3px 14px 12px 14px'
          }}
          ref={textContainerRef}
        >
          <Title
            text={article.title}
            lines={article.trimTitle ? 2 : 3}
            customStyle={{
              color: 'rgb(51, 51, 51)',
              direction: 'rtl',
              textAlign: 'right',
              fontFamily: 'Neue Haas Grotesk Bloomberg',
              fontWeight: 'bold',
              marginBottom: 25,
              fontSize: 18,
              lineHeight: '24px'
            }}
            fontSize={18}
          />
        </TextContainer>
      </GridMobileBackground>
      <GridCardLink isRearrange={isRearrange}>
        {isRearrange ? null : (
          <Link
            to={
              article.isShortHandArticle
                ? `/specials/${article.slug}`
                : `/${language}/${article.slug}`
            }
            state={{ fromGrid: true }}
          >
            {article.isLiveArticle && (
              <div
                style={{
                  position: 'absolute',
                  top: 17,
                  left: 5,
                  zIndex: 999
                }}
              >
                <LiveIcon label={'تغطية مستمرة'} />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                zIndex: 999
              }}
            >
              <SocialControls
                itemType={itemType}
                shareUri={
                  article.isShortHandArticle
                    ? `/specials/${article.slug}`
                    : `/${language}/${article.slug}`
                }
                visible={isHover || isTouchDevice()}
                theme="light"
                article={article}
                hideOnMobile={true}
                subMode={subMode}
              />
            </div>
            {article.mainVideoUrl && loadedVideo && (
              <VideoAudioButton
                articleId={article.id}
                visible={shareVisible}
                videoSrc={article.mainVideoUrl}
                templateNumber={type}
              />
            )}
          </Link>
        )}
      </GridCardLink>
    </div>
  )
}

export default MobileTemplateCard
