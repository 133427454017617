import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const ArticlesContainer = styled.div`
  max-width: 1300px;
  margin: -40px auto 70px;
  width: 100%;
  @media ${device.s} {
    margin: -40px auto 60px;
  }
`
export const ArticlesCardRow = styled.div`
  display: flex;
  flex-direction: row;

  a:last-child {
    margin-left: 0;
  }

  @media ${device.s} {
    flex-direction: column;
    a:last-child {
      margin: 0;
    }
  }
`
export const ArticleCard = styled.a`
  width: ${({ noOfItems }) => (noOfItems ? 100 / noOfItems : 25)}%;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 0px 0px 0px 20px;

  img {
    height: 233px;
    width: 100%;
    object-fit: cover;
  }

  @media ${device.s} {
    width: 100%;
    margin: 0 0 20px;

    img {
      height: 210px;
    }
  }
`
export const ArticleCardDetailContainer = styled.div`
  height: 157px;
  background-color: #ffffff;
  font-family: 'Neue Haas Grotesk Bloomberg';
  padding: 10px 10px;
  border-radius: 0 0 0 20px;
  line-height: 160%;

  @media ${device.s} {
    height: auto;
    max-height: 135px;
  }
`
export const ArticleCardLabel = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 0 9px 0 rgb(0 0 0 / 20%);
  font-family: 'Neue Haas Grotesk Bloomberg';
  line-height: 1.5em;
  font-weight: bold;
  font-size: 24px;
  border-top: 3px solid #c41b1a;
  border-right: 3px solid #c41b1a;
  background-color: #fff;
  color: #c41b19;
  border-radius: 0 18px 0 0px;
  height: 34px;
  width: fit-content;
  margin-right: 16px;
  padding: 10px 22px 5px 22px;

  img {
    width: 11px;
    height: 36px;
  }

  :hover {
    cursor: 'pointer';
    user-select: none;
    background-color: #c41b19;
    color: #fff;
    .whiteArrow {
      display: flex;
    }
    .redArrow {
      display: none;
    }
    a {
      color: #fff;
    }
  }

  .whiteArrow {
    display: none;
  }

  a {
    text-decoration: none;
    display: flex;
    color: #c41b19;
  }

  @media ${device.s} {
    font-size: 20px;
    padding: 10px 15px 5px 15px;
  }
`
export const CategoryLabelImg = styled.img`
  display: flex;
  margin-right: 24px;

  @media ${device.s} {
    margin-right: 18px;
  }
`
export const ArticleCardTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  height: 110px;
  overflow: hidden;

  @media ${device.s} {
    height: auto;
    font-size: 18px;
    overflow: unset;
  }
`
export const ArticleCardDate = styled.div`
  font-size: 14px;
  color: #707070;
  font-weight: 400;
  font-family: 'Neue Haas Grotesk Bloomberg';
  position: relative;
  top: 20px;

  @media ${device.s} {
    top: 0px;
    margin-top: 10px;
  }
`
