import { useEffect, useState } from "react"

import Fingerprint2 from "fingerprintjs2"

const GetFingerprint = () => {
  const [fingerprint, setFingerprint] = useState(null)

  useEffect(() => {
    setFingerprint(localStorage.getItem("fingerprint"))
  }, [])

  useEffect(() => {
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        fingerprintReport()
      })
    } else {
      setTimeout(() => {
        fingerprintReport()
      }, 500)
    }
  }, [fingerprint])

  let fingerprintReport = function() {
    Fingerprint2.get(function(components) {
      let fingerprintId = Fingerprint2.x64hash128(
        components
          .map(function(pair) {
            return pair.value
          })
          .join(),
        31
      )
      localStorage.setItem("fingerprint", fingerprintId)
      setFingerprint(fingerprintId)
    })
  }

  return fingerprint
}

export const generateFP = () =>
  new Promise(resolve => {
    Fingerprint2.getPromise({}).then(function(components) {
      let fingerprintId = Fingerprint2.x64hash128(
        components
          .map(function(pair) {
            return pair.value
          })
          .join(),
        31
      )

      resolve(fingerprintId)
    })
  })

export default GetFingerprint
