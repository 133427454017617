import React from 'react'
import isEqual from 'lodash/isEqual'
import LazyLoad from 'react-lazyload'
import DateTimeService from '../../utils/common'
import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))

import {
  VODContainer,
  VODInnerContainer,
  VODTitle,
  VODCardRow,
  VODCard,
  VODCardDetailContainer,
  VODCardDetail,
  VODDate,
  VODCardTitle,
  VODVerticalLine,
  VODCardDuration
} from './styles'

export default class VODCardContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps)
  }
  render() {
    const { data, isMobile, isRearrange } = this.props

    if (isRearrange) {
      return null
    }

    return (
      <LazyLoad height={'100%'} offset={isMobile ? 300 : 500}>
        <VODContainer>
          <VODInnerContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <VODVerticalLine width="3px" height="41px" />
              <VODTitle
                rel="noopener noreferrer nofollow"
                target="_blank"
                href="https://now.asharq.com/"
              >
                {'فيديو الشرق  NOW '}
              </VODTitle>
            </div>
            <VODCardRow>
              {data.map((card, index) => (
                <VODCard
                  key={`vod-card-${index}`}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                  href={card.shortUrl}
                  noOfItems={data.length}
                >
                  <div
                    style={{
                      position: 'relative',
                      display: 'flex'
                    }}
                  >
                    <img
                      src={card.data.Thumbnail}
                      alt="vod image"
                      loading="lazy"
                    />
                    <VODCardDuration>{card.data.Duration}</VODCardDuration>
                  </div>
                  <VODCardDetailContainer>
                    <VODCardDetail>
                      <VODVerticalLine />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingRight: '20px'
                        }}
                      >
                        {card.data.dateTime && (
                          <VODDate>
                            <time
                              dateTime={DateTimeService.formattedDateTime(
                                card.data.dateTime
                              )}
                            >
                              {DateTimeService.formatInLocalTime(
                                card.data.dateTime
                              )}
                            </time>
                          </VODDate>
                        )}
                        <VODCardTitle>
                          <Ellipsis lines={3} text={card.title} />
                        </VODCardTitle>
                      </div>
                    </VODCardDetail>
                  </VODCardDetailContainer>
                </VODCard>
              ))}
            </VODCardRow>
          </VODInnerContainer>
        </VODContainer>
      </LazyLoad>
    )
  }
}
