import testMobile from '../../utils/testMobile'
import { getCategoryPage } from '../../utils/sendEvent'

export const gridPlacement = (location) => {
  const { pathname } = location
  const isMobile = testMobile()

  if (pathname === '/') {
    return {
      isMobile,
      page: 'Home'
    }
  } else if (getCategoryPage() || pathname.includes('category')) {
    return {
      isMobile,
      page: 'Category'
    }
  } else if (pathname.includes('/ar/')) {
    return {
      isMobile,
      page: 'Article'
    }
  } else if (pathname.includes('tags')) {
    return {
      isMobile,
      page: 'Tags'
    }
  }

  return {
    page: pathname,
    isMobile
  }
}
