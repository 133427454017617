import React from 'react'
import isEqual from 'lodash/isEqual'
import LazyLoad from 'react-lazyload'
import DateTimeService from '../../utils/common'

import redArrow from '../menu/redArrow.svg'
import whiteArrow from '../menu/whiteArrow.svg'
import AsahrqLogoFallback from '/static/asharq-logo-thumb@3x.png'

import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))
import { CONSTANTS } from '../../constants/app'

import {
  ArticlesContainer,
  ArticlesCardRow,
  ArticleCard,
  ArticleCardDetailContainer,
  ArticleCardLabel,
  CategoryLabelImg,
  ArticleCardTitle,
  ArticleCardDate
} from './styles'

export default class SportsArticleCardContainer extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps)
  }
  render() {
    const { data = [], isMobile, label = 'رياضة', isRearrange } = this.props

    if (isRearrange) {
      return null
    }

    return (
      <LazyLoad height={'100%'} offset={isMobile ? 300 : 500}>
        <ArticlesContainer>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <ArticleCardLabel>
              <a
                rel="noopener noreferrer nofollow"
                target="_blank"
                href={CONSTANTS.SPORTS_BASE_URL}
              >
                <div>{label}</div>
                <CategoryLabelImg
                  className="whiteArrow"
                  id="arrow"
                  src={whiteArrow}
                  alt="labelArrow"
                />
                <CategoryLabelImg
                  className="redArrow"
                  id="arrow"
                  src={redArrow}
                  alt="labelArrow"
                />
              </a>
            </ArticleCardLabel>
            <ArticlesCardRow>
              {data.map((item, index) => {
                return (
                  <ArticleCard
                    key={`sports-article-card-${index}`}
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                    href={`${CONSTANTS.SPORTS_BASE_URL}/article/${item.slug}`}
                    noOfItems={data.length}
                  >
                    <div
                      style={{
                        position: 'relative',
                        display: 'flex'
                      }}
                    >
                      {item.mainImageUrl ? (
                        <img
                          src={item.mainImageUrl}
                          alt={item.title}
                          loading="lazy"
                        />
                      ) : (
                        <img
                          src={AsahrqLogoFallback}
                          alt={item.title}
                          loading="lazy"
                        />
                      )}
                    </div>
                    <ArticleCardDetailContainer>
                      <ArticleCardTitle>
                        <Ellipsis lines={4} text={item.title} />
                      </ArticleCardTitle>
                      {item.publishedAt && (
                        <ArticleCardDate>
                          <time
                            dateTime={DateTimeService.formattedDateTime(
                              item.publishedAt
                            )}
                          >
                            {DateTimeService.formatInLocalTime(
                              item.publishedAt
                            )}
                          </time>
                        </ArticleCardDate>
                      )}
                    </ArticleCardDetailContainer>
                  </ArticleCard>
                )
              })}
            </ArticlesCardRow>
          </div>
        </ArticlesContainer>
      </LazyLoad>
    )
  }
}
