import { connect } from 'react-redux'
import { useDrag, DragPreviewImage } from 'react-dnd'
import { useInView } from 'react-hook-inview'
import React, { useState, useEffect } from 'react'
import loadable from '@loadable/component'
import useMergedRef from '@react-hook/merged-ref'
import getImgixUrl from '../../utils/getImgixUrl.js'
import { breakpoints } from '../../constants/app'

import closeSrc from './close.svg'
import queryString from 'query-string'

import {
  GridCard,
  GridDeleteCard,
  GridDeleteIcon,
  GridCardId
} from './grid.styled'
import { gridSpanHelper, getSize, useWindowSize } from './helper'
import { sendEventItemClick } from '../../utils/sendEvent'
import { useScrollDirection } from '../../utils/useScrollDirection'
import GridDropOverlay from './dropOverlay.js'
import LatestNews from '../LatestNews'
import Vote from './vote'
import LazyLoadTemplateCard from './lazyLoadTemplateCard'
import LiveTvPlayer from '../liveTvPlayer'

const AdHolder = loadable(() => import('../holder'))

const Card = (props) => {
  const {
    categoryLabel,
    hideDrop = false,
    hideDelete = false,
    article,
    index,
    page,
    rowLength,
    rowIndex,
    rowHasLatestNews,
    isLatestNews,
    rowHasVote,
    isVote,
    initializeVote,
    setDeleteArticleIds,
    deleteArticleIds,
    replaceArticles,
    moveRight,
    moveLeft,
    moveBottom,
    isRearrange,
    deleteCard,
    newWidth,
    newHeight,
    newMarginBottom,
    newMarginLeft,
    videoPlayingIdMobile,
    disableDropBottom = false,
    mode,
    adType = null,
    subMode,
    pageAdConfig = null
  } = props

  const parameterTemplateNumberStr = queryString.parse(
    location.search
  ).templateNumber
  const [width] = useWindowSize()
  const isMobile = width < breakpoints.m ? true : false
  const scrollDirection = useScrollDirection()

  let newTemplateNumber
  if (parameterTemplateNumberStr == null) {
    if (article.type === 'headline') {
      // default the template number to 2 if its headline
      newTemplateNumber = 2
    } else if (isMobile) {
      // default the template number to 6 if its mobile width
      newTemplateNumber = 6
    } else {
      newTemplateNumber = article.templateNumber
    }
  } else {
    newTemplateNumber = parseInt(parameterTemplateNumberStr)
  }
  const itemType =
    article.mainImage && !article.mainVideoUrl ? 'article' : 'video'

  const [{ isDragging = false, isDragElement = false }, drag, preview] =
    isRearrange
      ? useDrag({
          type: 'CARD',
          item: { articleId: article.id, article },
          collect: (monitor) => ({
            isDragging: !!monitor.getItem(),
            isDragElement: monitor.isDragging()
          })
        })
      : [{}]

  const [shareVisible, setShareVisible] = useState(false)
  const [loadedVideo, setLoadedVideo] = useState(false)
  const [ref, inView] = useInView({ threshold: 0.1 })

  const gridSize = getSize(width)

  const shouldLoadLazily = (mode, rowIndex) => {
    // lazily load card in article page
    if (mode === 'article') {
      return true
    }

    // lazily load card in mobile view after first row
    if (isMobile && rowIndex && rowIndex > 0) {
      return true
    }

    // lazily load card in desktop view after second row to avoid blank card being displayed
    if (!isMobile && rowIndex && rowIndex > 1) {
      return true
    }

    return false
  }

  const { gridRow, gridColumn, cardType, textOutside, cardSize } =
    gridSpanHelper(
      index,
      4,
      gridSize,
      rowLength,
      rowIndex,
      rowHasLatestNews,
      isLatestNews,
      rowHasVote,
      isVote
    )

  let multiRef

  if (isRearrange) {
    multiRef = useMergedRef(useMergedRef(ref, drag) /*, drop*/)
  }

  const isLarge = gridColumn !== 'span 3'

  const { id } = article

  useEffect(() => {
    if (isVote && window && window.addEventListener) {
      initializeVote()
    }
  }, [])

  useEffect(() => {
    if (inView) {
      props.addEvent(scrollDirection, article, page)
    }
  }, [inView])

  if (adType === 'native') {
    return (
      <GridCard
        className="inlineAdCard"
        style={{
          width: newWidth ? newWidth : undefined,
          height: newHeight ? newHeight : undefined,
          marginBottom: newMarginBottom ? newMarginBottom : undefined,
          marginLeft: newMarginLeft ? newMarginLeft : undefined
        }}
        key={`article_${index}`}
        data-ui-column={isNaN(article.uiColumns) ? 4 : article.uiColumns}
        gridRow={gridRow}
        gridColumn={gridColumn}
        ref={ref}
      >
        <AdHolder page={pageAdConfig.page} type="native" rowIndex={rowIndex} />
      </GridCard>
    )
  }

  if (article.latestNews) {
    return (
      <div
        style={{
          gridColumn: gridColumn,
          gridRow: gridRow,
          marginBottom: '10px'
        }}
      >
        <div
          style={{
            background: 'white',
            border: '1px solid #ECECEC',
            borderRadius: '0 15px',
            height: 'calc(100% - 150px)',
            marginBottom: '5px'
          }}
        >
          <LatestNews
            disableDropLeft={rowLength >= 5}
            disableDropBottom={disableDropBottom}
            onDropBottom={
              isRearrange
                ? (item) => {
                    moveBottom(item.articleId, item.article)
                  }
                : null
            }
            onDropLeft={
              isRearrange
                ? (item) => {
                    const dragArticleId = item.articleId
                    const hoverArticleId = undefined
                    const articleData = item.article

                    if (dragArticleId === hoverArticleId) return
                    moveLeft(dragArticleId, hoverArticleId, item.article)
                  }
                : null
            }
            scrollable
            poll
          />
        </div>
        <div style={{ width: '100%', height: '150px' }}>
          <LiveTvPlayer />
        </div>
      </div>
    )
  } else if (article.vote) {
    return <Vote style={{ gridColumn: gridColumn, gridRow: gridRow }} />
  } else {
    return (
      <>
        {isRearrange && (
          <DragPreviewImage
            connect={preview}
            src={
              getImgixUrl({
                cardType: 1,
                imageMeta: article.mainImage,
                src: article.mainImageUrl,
                imageQuality: 60,
                imageDpr: 1,
                newHeight: 200,
                newWidth: 280
              }).url
            }
          />
        )}
        <GridCard
          as={article.isShortHandArticle ? 'a' : 'div'}
          className={props.className}
          style={{
            width: newWidth ? newWidth : undefined,
            height: newHeight ? newHeight : undefined,
            marginBottom: newMarginBottom ? newMarginBottom : undefined,
            marginLeft: newMarginLeft ? newMarginLeft : undefined
          }}
          key={`article_${index}`}
          data-ui-column={isNaN(article.uiColumns) ? 4 : article.uiColumns}
          gridRow={gridRow}
          gridColumn={gridColumn}
          ref={isRearrange ? multiRef : ref}
          onClick={() => {
            const customPage =
              subMode === 'live-article' ? 'livearticlepage' : null
            sendEventItemClick(
              {
                itemId: id,
                pos: {
                  column: index + 1,
                  row: rowIndex + 1,
                  category: categoryLabel
                },
                itemType:
                  article.mainVideoUrl || article.type === 'video'
                    ? 'video'
                    : article.type === 'headline'
                    ? 'headline'
                    : 'article'
              },
              customPage
            )
            dataLayer.push({
              event: 'asEvent',
              analyticsEventLabel: 'Navigation',
              eventCategory: 'Navigation',
              eventAction: 'Headline - Click',
              eventLabel: article.title,
              publish_date: `${article.publishedAt}`
            })
          }}
          templateNumber={newTemplateNumber}
          onMouseEnter={() => {
            // GTM event for hover
            dataLayer.push({
              event: 'asEvent',
              analyticsEventLabel: 'Navigation',
              eventCategory: 'Navigation',
              eventAction: 'Headline - Hover',
              eventLabel: article.title,
              publish_date: `${article.publishedAt}`
            })
          }}
        >
          <LazyLoadTemplateCard
            type={newTemplateNumber}
            preview={preview}
            index={index}
            article={article}
            setLoadedVideo={setLoadedVideo}
            loadedVideo={loadedVideo}
            cardType={cardType}
            textOutside={textOutside}
            isRearrange={isRearrange}
            language={props.language}
            shareVisible={shareVisible}
            gridSize={gridSize}
            videoPlayingIdMobile={videoPlayingIdMobile}
            isLarge={isLarge}
            gridRow={gridRow}
            gridColumn={gridColumn}
            itemType={itemType}
            cardSize={cardSize}
            newHeight={newHeight}
            isMobile={isMobile}
            shouldLoadLazily={shouldLoadLazily(mode, rowIndex)}
            subMode={subMode}
          />
          {isRearrange && isDragging && !isDragElement && !hideDrop ? (
            <GridDropOverlay
              disableDropLeft={
                (rowLength >= 5 && rowIndex === 0) ||
                (rowIndex === 1 && rowLength >= 4)
              }
              disableDropRight={
                (rowLength >= 5 && rowIndex === 0) ||
                (rowIndex === 1 && rowLength >= 4)
              }
              onDropLeft={(item) => {
                const dragArticleId = item.articleId
                const hoverArticleId = article.id
                const articleData = item.article

                if (dragArticleId === hoverArticleId) return
                moveLeft(dragArticleId, hoverArticleId, item.article)
              }}
              onDropRight={(item) => {
                const dragArticleId = item.articleId
                const hoverArticleId = article.id
                const articleData = item.article
                if (dragArticleId === hoverArticleId) return
                moveRight(dragArticleId, hoverArticleId, item.article)
              }}
              onDropCenter={(item) => {
                const dragArticleId = item.articleId
                const hoverArticleId = article.id
                const articleData = item.article

                if (dragArticleId === hoverArticleId) return
                replaceArticles(dragArticleId, hoverArticleId, articleData)
              }}
            />
          ) : null}

          {isRearrange && !hideDelete ? (
            <GridDeleteCard
              onClick={() => {
                const ids = deleteArticleIds
                ids.push(article.id)
                setDeleteArticleIds(ids)

                deleteCard(article.id)
              }}
            >
              <GridDeleteIcon alt="close" src={closeSrc} />
            </GridDeleteCard>
          ) : null}
          {isRearrange ? (
            <GridCardId
              onClick={() => {
                navigator.clipboard.writeText(article.id).then((message) => {
                  alert(`id ${article.id} copied to clipboard`)
                })
              }}
            >
              {article.id}
            </GridCardId>
          ) : null}
        </GridCard>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCard: (articleId) => {
      const payload = {
        articleId
      }
      dispatch({ type: 'ARTICLES_REARRANGE_DELETE', payload })
    },
    addEvent: (scrollDirection, article, page) => {
      const payload = {
        scrollDirection,
        article,
        page
      }
      dispatch({ type: 'ADD_EVENT_REQUEST', payload })
    },
    replaceArticles: (dragArticleId, hoverArticleId, articleData) => {
      const payload = { dragArticleId, hoverArticleId, articleData }
      dispatch({ type: 'ARTICLES_REPLACE', payload })
    },
    moveRight: (dragArticleId, dropArticleId, articleData) => {
      const payload = { dragArticleId, dropArticleId, articleData }
      dispatch({ type: 'ARTICLES_MOVE_RIGHT', payload })
    },
    moveLeft: (dragArticleId, dropArticleId, articleData) => {
      const payload = { dragArticleId, dropArticleId, articleData }
      dispatch({ type: 'ARTICLES_MOVE_LEFT', payload })
    },
    moveBottom: (dragArticleId, articleData) => {
      const payload = { dragArticleId, articleData }
      dispatch({ type: 'ARTICLES_MOVE_BOTTOM', payload })
    }
  }
}

export default connect(null, mapDispatchToProps)(Card)
