import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import Countdown, { zeroPad } from 'react-countdown'
import { isEmpty } from 'lodash'

import {
  BannerContainer,
  CountDown,
  CountDownContainer,
  TextContainer,
  Text,
  SubText,
  Loader
} from './banner.styled'
import { Spinner, GridError } from '../grid/grid.styled'
import testMobile from '../../utils/testMobile'
import signImgix from '../../utils/signImgix'

import { STAGING_URL, PRODUCTION_URL } from './constants'

const Banner = props => {
  const isClient = typeof window === 'object'
  // making it false by default to fix the isse of showing desktop banner in desktop when opening link in new tab
  const [isMobile, setIsMobile] = useState(false)
  const { fetchBannerData, banner, page } = props
  const bannerImageSrc =
    process.env.GATSBY_ENVIRONMENT === 'production'
      ? PRODUCTION_URL
      : STAGING_URL

  useEffect(() => {
    if (!isClient) {
      return () => {}
    }

    if (page !== 'us-election') {
      fetchBannerData({})
    }

    const handleResize = () => {
      setIsMobile(testMobile())
    }

    window.addEventListener('resize', handleResize)

    // fix the issue of showing desktop banner in desktop when opening link in new tab
    setIsMobile(testMobile())

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getCounterPlacement = placement => {
    if (placement === 'left') {
      return '15%'
    } else if (placement === 'right') {
      return '85%'
    }

    return '50%'
  }

  const renderCountDown = ({ days, hours, minutes, seconds, completed }) => {
    const { data } = banner
    if (completed) {
      return null
    }

    const {
      desktopCounterPlacement,
      mobileCounterPlacement,
      counterColor,
      counterTextColor
    } = data
    const counterPlacement = getCounterPlacement(
      isMobile ? mobileCounterPlacement : desktopCounterPlacement
    )

    return (
      <CountDown counterPlacement={counterPlacement}>
        <CountDownContainer>
          <TextContainer>
            <Text textColor={counterColor}>{days}</Text>
            <SubText textColor={counterTextColor}>{`أيام`}</SubText>
          </TextContainer>
          <Text textColor={counterColor}>{`:`}</Text>
          <TextContainer>
            <Text textColor={counterColor}>{zeroPad(hours, 2)}</Text>
            <SubText textColor={counterTextColor}>{`ساعات`}</SubText>
          </TextContainer>
          <Text textColor={counterColor}>{`:`}</Text>
          <TextContainer mobileWidth={'22px'} desktopWidth={'50px'}>
            <Text textColor={counterColor}>{zeroPad(minutes, 2)}</Text>
            <SubText textColor={counterTextColor}>{`دقائق`}</SubText>
          </TextContainer>
          <Text textColor={counterColor}>{`:`}</Text>
          <TextContainer mobileWidth={'22px'} desktopWidth={'50px'}>
            <Text textColor={counterColor}>{zeroPad(seconds, 2)}</Text>
            <SubText textColor={counterTextColor}>{`ثواني`}</SubText>
          </TextContainer>
        </CountDownContainer>
      </CountDown>
    )
  }

  // for US-Election page which is not yet removed
  if (page === 'us-election') {
    return (
      <BannerContainer page={page}>
        <img
          src={
            isMobile
              ? signImgix(bannerImageSrc['BANNER_MOBILE'])
              : signImgix(bannerImageSrc['BANNER_DESKTOP'])
          }
          alt="Banner"
          loading="lazy"
        />
      </BannerContainer>
    )
  }

  if (banner.isFetching) {
    return (
      <BannerContainer>
        <Loader>
          <Spinner />
        </Loader>
      </BannerContainer>
    )
  }

  if (banner.isError) {
    return (
      <BannerContainer>
        <Loader>
          <GridError>Unexpected Error</GridError>
        </Loader>
      </BannerContainer>
    )
  }

  return (
    banner &&
    !isEmpty(banner.data) &&
    banner.data.showHomeBanner && (
      <BannerContainer page={page}>
        <Link
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          to={banner.data.url ? encodeURI(banner.data.url) : '/'}
        >
          <img
            style={{
              borderRadius: '0px 14px 0px 14px'
            }}
            src={isMobile ? banner.data.mobileImage : banner.data.desktopImage}
            alt="Home Banner"
            loading="lazy"
          />
          {banner.data.startDate && (
            <Countdown
              date={banner.data.startDate}
              renderer={renderCountDown}
            />
          )}
        </Link>
      </BannerContainer>
    )
  )
}

const mapStateToProps = ({ banner }) => {
  return {
    banner
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchBannerData: payload => {
      dispatch({ type: 'BANNER_REQUESTED', payload })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
