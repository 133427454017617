import styled from 'styled-components'
import { device } from '../../utils/screenSizes'

export const BannerContainer = styled.div`
  max-width: ${({ page }) => (page === 'homepage' ? '1300px' : '100%')};
  margin: ${({ page }) =>
    page === 'homepage' ? '129px auto -160px' : '0 0 -130px 0'};
  padding: ${({ page }) =>
    page === 'homepage' ? '20px 0 10px 0' : '129px 0 0 0'};

  @media ${device.s} {
    margin: ${({ page }) =>
      page === 'homepage' ? '-35px 0 0' : '0 0 -100px 0'};
    padding: ${({ page }) =>
      page === 'homepage' ? '20px 0px 10px' : '120px 0 0 0'};
  }

  position: relative;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
  }
`

export const CountDown = styled.div`
  position: absolute;
  top: 50%;
  left: ${({ counterPlacement }) => counterPlacement};
  direction: ltr;
  transform: translate(-50%, -50%);

  @media ${device.s} {
    top: 54%;
  }
`
export const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  width: ${({ desktopWidth }) => (desktopWidth ? desktopWidth : 'auto')};

  @media ${device.s} {
    padding: 0;
    width: ${({ mobileWidth }) => (mobileWidth ? mobileWidth : 'auto')};
    min-height: 25px;
  }
`

export const SubText = styled.span`
  color: ${({ textColor }) =>
    textColor && textColor !== '' ? textColor : 'rgb(196, 27, 26)'};
  font-size: 15px;
  font-family: 'Neue Haas Grotesk Bloomberg';
  font-weight: 900;

  @media ${device.s} {
    font-size: 8px;
  }
`
export const Text = styled.span`
  font-family: 'Neue Haas Grotesk Bloomberg';
  color: ${({ textColor }) =>
    textColor && textColor !== '' ? textColor : 'rgb(25, 45, 116)'};
  font-size: 36px;
  font-weight: 900;

  @media ${device.s} {
    font-size: 14px;
  }
`
export const Loader = styled.div`
  grid-column: 1/-1;
  text-align: center;
  padding-top: 50px;

  @media ${device.s} {
    margin-top: -150px;
    margin-bottom: 50px;
  }
`
