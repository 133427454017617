import React from 'react'
import TemplateCard1 from './templates/TemplateCard1'
import TemplateCard2 from './templates/TemplateCard2'
import TemplateCard3 from './templates/TemplateCard3'
import TemplateCard4 from './templates/TemplateCard4'
import TemplateCard5 from './templates/TemplateCard5'
import MobileTemplateCard from './templates/MobileTemplateCard'

export const TemplateCard = props => {
  const { type } = props
  if (type === 2) {
    return <TemplateCard2 {...props} />
  } else if (type === 3) {
    return <TemplateCard3 {...props} />
  } else if (type === 4) {
    return <TemplateCard4 {...props} />
  } else if (type === 5) {
    return <TemplateCard5 {...props} />
  } else if (type === 6) {
    return <MobileTemplateCard {...props} />
  } else {
    // default type
    return <TemplateCard1 {...props} />
  }
}
