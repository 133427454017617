import React from 'react'
import Truncate from 'react-truncate'

import loadable from '@loadable/component'
const Ellipsis = loadable(() => import('react-ellipsis-pjs'))

const Description = ({ lines, text, articleId, fontSize = '0.95em' }) => {
  return (
    <div
      style={{
        wordBreak: 'normal',
        color: 'rgb(132, 132, 132)',
        direction: 'rtl',
        textAlign: 'right',
        fontFamily: 'Neue Haas Grotesk Bloomberg',
        margin: 0,
        fontSize,
        lineHeight: '25px',
        overflow: 'hidden',
        boxSizing: 'border-box'
      }}
    >
      <Truncate lines={lines} ellipsis={<span>...</span>}>
        {text}
      </Truncate>
    </div>
  )
}

const Title = ({
  lines = 3,
  text,
  shadow = false,
  color = 'rgb(51, 51, 51)',
  fontSize,
  customStyle
}) => {
  const style = customStyle || {
    wordBreak: 'normal',
    color,
    direction: 'rtl',
    textAlign: 'right',
    fontFamily: 'Neue Haas Grotesk Bloomberg',
    fontWeight: 'bold',
    margin: 0,
    fontSize: fontSize ? fontSize : 26,
    lineHeight: '28px',
    textShadow: shadow ? '1px 1px 3px rgba(0, 0, 0, 0.9)' : 'none'
  }

  if (lines === 0) {
    return <div style={style}>{text}</div>
  }

  return <Ellipsis lines={lines} style={style} text={text} />
}

export { Title, Description }
