import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ChopLines from 'chop-lines'
import { Title, Description } from '../Ellipsis'

import SocialControls from '../../socialcontrols/socialcontrols'
import { GridCardLink, TextContainerForType2 } from '../grid.styled'
import { useWindowSize } from '../helper'
import { breakpoints } from '../../../constants/app'
import { calculateDescriptionHeight } from '../helper'
import LiveIcon from '../../liveIcon'

const BODY_LINE_HEIGHT = 26

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  padding: 12px 14px 12px 14px;
`

const TemplateCard2 = ({
  article,
  isRearrange,
  language,
  itemType,
  cardSize,
  subMode
}) => {
  const bodyRef = useRef(null)
  const textContainerRef = useRef(null)
  const [state, setState] = useState({ bodyHeight: 0, lines: 0 })

  const [width] = useWindowSize()
  const isMobile = width < breakpoints.m
  const titleRef = useRef(null)
  const spaceBetweenTitleAndDescRef = useRef(null)
  const spaceAfterDescRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      const descHeight = calculateDescriptionHeight(
        textContainerRef,
        titleRef,
        spaceAfterDescRef,
        spaceBetweenTitleAndDescRef,
        state,
        BODY_LINE_HEIGHT
      )
      if (descHeight) {
        setState(descHeight)
      }
    }, 100)
  }, [bodyRef])

  /*const calculateTitleRows = () => {
    const node = textContainerRef.current

    if (node) {
      const paddingTop = parseInt(getComputedStyle(node).paddingTop)
      const paddingBottom = parseInt(getComputedStyle(node).paddingBottom)
      const height = node.offsetHeight - paddingTop - paddingBottom
      const rows = Math.floor(height / 28)

      return rows
    }

    return 0
  }*/

  const getTitleMaxLines = () => {
    if (article.trimTitle) {
      if ((cardSize && cardSize === '1/4') || isMobile) {
        return 2
      }
      return 3
    }

    if (cardSize) {
      if (cardSize === '1/4') {
        return 4
      } else if (cardSize === '1/2' || isMobile) {
        return 5
      } else if (cardSize === '1/3') {
        return 6
      }
    }

    return 3
  }

  const linkUrl =
    article.type === 'headline'
      ? `/latest-news/${article.id}/`
      : article.isShortHandArticle
      ? `/specials/${article.slug}`
      : `/${language}/${article.slug}`

  return (
    <>
      <Background>
        <TextContainerForType2 ref={textContainerRef}>
          <div ref={titleRef}>
            {cardSize && cardSize === '1' ? (
              <span
                style={{
                  wordBreak: 'normal',
                  color: 'rgb(51, 51, 51)',
                  direction: 'rtl',
                  textAlign: 'right',
                  fontFamily: 'Neue Haas Grotesk Bloomberg',
                  fontWeight: 'bold',
                  margin: 0,
                  fontSize: isMobile ? 18 : 26,
                  lineHeight: '28px'
                }}
              >
                {article.title}
              </span>
            ) : (
              <Title
                text={article.title}
                fontSize={isMobile ? 18 : 26}
                lines={getTitleMaxLines()}
              />
            )}
          </div>

          <div ref={spaceBetweenTitleAndDescRef} style={{ minHeight: '10%' }} />

          {article.cardSummary ? (
            <div
              ref={bodyRef}
              style={{
                height: `${state.bodyHeight}px`,
                fontSize: '15px',
                fontFamily: 'Neue Haas Grotesk Bloomberg',
                color: 'rgb(132, 132, 132)',
                lineHeight: '26px',
                textAlign: 'right'
              }}
              data-lines={state.lines}
            >
              {state.lines > 0 && (
                <ChopLines
                  lines={state.lines}
                  lineHeight={26}
                  ellipsis={<span />}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article.cardSummary
                    }}
                  />
                </ChopLines>
              )}
            </div>
          ) : (
            <div
              ref={bodyRef}
              style={{
                height: `${state.bodyHeight}px`,
                background: 'white'
              }}
            >
              {state.lines > 0 && (
                <Description
                  text={article.contentAr}
                  fontSize={'15px'}
                  lines={state.lines}
                />
              )}
            </div>
          )}
          <div ref={spaceAfterDescRef} style={{ minHeight: '12%' }} />
        </TextContainerForType2>
      </Background>
      <GridCardLink isRearrange={isRearrange}>
        {isRearrange ? null : (
          <Link to={linkUrl} state={{ fromGrid: true }}>
            {article.isLiveArticle && (
              <div
                style={{
                  position: 'absolute',
                  top: 17,
                  left: 10,
                  zIndex: 999
                }}
              >
                <LiveIcon label={'تغطية مستمرة'} isDarkLabel />
              </div>
            )}
            <div
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
                zIndex: 999
              }}
            >
              <SocialControls
                itemType={itemType}
                shareUri={linkUrl}
                visible={true}
                theme="lightgrey"
                themehover="midgrey"
                article={article}
                hideOnMobile={true}
                subMode={subMode}
              />
            </div>
          </Link>
        )}
      </GridCardLink>
    </>
  )
}

export default TemplateCard2
