import React, { useState, useRef } from 'react'
import { toastr } from 'react-redux-toastr'

import {
  TemplateCardBackground,
  TextContainer,
  DateContainer,
  Duration,
  FooterContainer
} from '../grid.styled'
import BackgroundCover from '../../background/background'

import SocialControls from '../../socialcontrols/socialcontrols'
import DateTimeService from '../../../utils/common'
import { Title } from '../Ellipsis'
import '../template.scss'

const TemplateCard5 = ({
  preview,
  index,
  article,
  setLoadedVideo,
  cardType,
  type,
  itemType,
  language,
  subMode
}) => {
  const textContainerRef = useRef(null)
  const [isHover, setIsHover] = useState(false)

  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() =>
        toastr.success('Item clicked', {
          timeOut: 300
        })
      }
    >
      <TemplateCardBackground flexDirection="column">
        <BackgroundCover
          preview={preview}
          isHover={isHover}
          index={index}
          src={article.mainImageUrl}
          imageDpr={1}
          imageQuality={60}
          imageMeta={article.mainImage}
          videoSrc={article.mainVideoUrl}
          setLoadedVideo={setLoadedVideo}
          cardType={cardType}
          disableControls={true}
          loopVideo={true}
          autoplay={article.autoPlayGridVideo}
          backgroundHeight={'60%'}
          title={article.title}
          lazyload={true}
          noBorderRadius={true}
          templateNumber={type}
          articleId={article.id}
        />

        <TextContainer ref={textContainerRef} f1>
          <DateContainer>
            <time
              dateTime={DateTimeService.formattedDateTime(
                article.publishedAt || article.createdAt || article.updatedAt
              )}
            >
              {DateTimeService.formatInLocalTime(
                article.publishedAt || article.createdAt || article.updatedAt,
                'dd MMMM yyyy'
              )}
            </time>
          </DateContainer>
          <div>
            <Title text={article.title} lines={1} fontSize={17} />
          </div>
          <FooterContainer id="cardFooter">
            <Duration>{article.duration}</Duration>
            <SocialControls
              itemType={itemType}
              shareUri={
                article.isShortHandArticle
                  ? `/specials/${article.slug}`
                  : `/${language}/${article.slug}`
              }
              visible={true}
              theme="dark"
              article={article}
              hideOnMobile={true}
              subMode={subMode}
            />
          </FooterContainer>
        </TextContainer>
      </TemplateCardBackground>
    </div>
  )
}

export default TemplateCard5
