import React, { Component } from 'react'
import { Link } from 'gatsby'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isEqual, sortBy } from 'lodash'
import loadable from '@loadable/component'
import {
  GridLoader,
  Spinner,
  CardsContainer,
  CategoryLabel,
  CategoryFooter,
  CategoryLabelImg,
  ImgContainer,
  GridAdCard,
  BannerGrid
} from './grid.styled'
import Card from './card'
import whiteArrow from '../menu/whiteArrow.svg'
import redArrow from '../menu/redArrow.svg'
import { gridPlacement } from '../holder/constant'
import testMobile from '../../utils/testMobile'
import VODCardContainer from '../vodCardContainer'
import AsharqBusinessComponent from '../asharqBusiness'
import SportsArticleCardContainer from '../sportsArticleCardContainer'

const AdHolder = loadable(() => import('../holder'))
const Footer = loadable(() => import('../footer/footer'))
export default class GridList extends Component {
  constructor(props) {
    super(props)

    const { page, isMobile } = gridPlacement(this.props.location)

    this.state = {
      pageAdConfig: this.getAddConfigFromProps(),
      page,
      isMobile
    }

    this.isMobile = testMobile()
    this.firstNativeAdIndex = null
    this.lastRepeatAdIndex = 0
    this.skippedAdInVodNow = null

    // home page recommendation section
    this.firstRecommendationNativeAdIndex = null
    this.lastRepeatRecommendationAdIndex = 0
    this.recommendationIndex = null
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.mode === 'rearrange') {
      return true
    }

    const shouldRender = !isEqual(this.props.gridResults, nextProps.gridResults)
    return shouldRender
  }

  /**
   * To get the ad config from props and set it in state
   * @returns {*} config object or null
   */
  getAddConfigFromProps = () => {
    const { adsManager, location } = this.props
    const { page, isMobile } = gridPlacement(location)
    if (adsManager && adsManager.fetched && adsManager.list) {
      const getConfig = adsManager.list.find((item) => {
        if (
          item.isActive &&
          item.page === page &&
          item.screen === (isMobile ? 'Mobile' : 'Desktop')
        ) {
          return item
        }
      })
      return getConfig
    }

    return null
  }

  /**
   * Page starting ad - Leaderboard
   * @param {} page
   * @param {*} isMobile
   * @param {*} index
   * @param {*} row
   * @param {*} placement
   */
  renderTopAd = () => {
    const { pageAdConfig, page, isMobile } = this.state
    const { mode } = this.props
    if (
      pageAdConfig &&
      pageAdConfig.leaderBoard > 0 &&
      !isMobile &&
      mode !== 'article'
    ) {
      return (
        <BannerGrid data-page-name={page} className="topBanner" position="top">
          <AdHolder page={pageAdConfig.page} place="top" type={'leaderBoard'} />
        </BannerGrid>
      )
    }

    return null
  }

  /**
   * Page Category ads
   * @param {} page
   * @param {*} isMobile
   * @param {*} index
   * @param {*} row
   * @param {*} placement
   */
  renderCategoryAd = (index, row, rowType, isRecommendationRow) => {
    const { pageAdConfig, page, isMobile } = this.state
    const { mode } = this.props

    if (
      typeof window !== 'undefined' &&
      window.adsConfig &&
      window.adsConfig.renderedAll
    ) {
      return null
    }

    if (pageAdConfig) {
      const recommendations = pageAdConfig.recommendations
      const recommendationsRepeat = pageAdConfig.recommendationsRepeat

      // billboard ads for desktop
      if (
        !isMobile &&
        pageAdConfig.billBoard &&
        pageAdConfig.billBoard.length &&
        !(
          pageAdConfig.billBoard.length === 1 && pageAdConfig.billBoard[0] === 0
        ) &&
        pageAdConfig.billBoard.includes(index + 1) // index starts from 0
      ) {
        return (
          <CardsContainer
            mode={mode}
            data-ad-container
            key={`category-ad-${index}`}
            data-page-name={page}
            data-is-category={row.isCategory}
            data-row-type={rowType}
            data-row-index={index}
            hasTopCategory={
              index === 0 && row.isCategory && row.label && row.isFirst
            }
            isCategory={row.isCategory && row.isLast}
            isRecommendation={row.isRecommendation}
            isAdContainer={true}
          >
            <GridAdCard>
              <AdHolder
                page={pageAdConfig.page}
                type={isMobile ? 'native-fluid' : 'billboard'}
                rowIndex={index}
              />
            </GridAdCard>
          </CardsContainer>
        )
      }

      // Ads for mobile devices - bill board ads
      if (isMobile) {
        const billBoard =
          pageAdConfig.billBoard && pageAdConfig.billBoard.length > 0
            ? sortBy(pageAdConfig.billBoard)
            : []
        if (
          billBoard &&
          billBoard.length > 0 &&
          !(billBoard.length === 1 && billBoard[0] === 0) &&
          billBoard.includes(index + 1) // index starts from 0
        ) {
          return (
            <BannerGrid data-page-name={page} data-ad-container="true">
              <AdHolder
                page={pageAdConfig.page}
                place="row"
                type="native"
                rowIndex={index}
              />
            </BannerGrid>
          )
        }

        // home page and article page recommendation
        if (
          isRecommendationRow &&
          (mode === 'homepage' || mode === 'article')
        ) {
          if (
            this.firstRecommendationNativeAdIndex === null &&
            recommendations > 0 &&
            index === recommendations + (this.recommendationIndex - 1)
          ) {
            this.firstRecommendationNativeAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }

          const repeatIndexRecommendation =
            this.lastRepeatRecommendationAdIndex === 0
              ? this.firstRecommendationNativeAdIndex + recommendationsRepeat
              : recommendationsRepeat + this.lastRepeatRecommendationAdIndex

          if (
            this.firstRecommendationNativeAdIndex !== null &&
            recommendationsRepeat > 0 &&
            index === repeatIndexRecommendation
          ) {
            this.lastRepeatRecommendationAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }

          return null
        }

        // category page render mobile ads after bill board ads are rendered as there are no separate recommendation section
        if (
          billBoard.length > 0 &&
          !(billBoard.length === 1 && billBoard[0] === 0) &&
          index > billBoard[billBoard.length - 1]
        ) {
          const lastBillBoardIndex = billBoard[billBoard.length - 1]

          const recomm =
            recommendations >= 1 ? recommendations - 1 : recommendations

          // In Mobile, reduce Index by 1 to match with configuration
          if (
            index === lastBillBoardIndex + recomm &&
            this.firstNativeAdIndex === null &&
            recommendations > 0
          ) {
            this.firstNativeAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }

          const repeatIndex =
            this.lastRepeatAdIndex === 0
              ? this.firstNativeAdIndex + recommendationsRepeat
              : recommendationsRepeat + this.lastRepeatAdIndex

          if (
            this.firstNativeAdIndex !== null &&
            recommendationsRepeat > 0 &&
            index === repeatIndex
          ) {
            this.lastRepeatAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }
          // No Billboard
        } else if (
          billBoard.length === 0 ||
          (billBoard.length === 1 && billBoard[0] === 0)
        ) {
          if (
            index === recommendations - 1 &&
            this.firstNativeAdIndex === null &&
            recommendations > 0
          ) {
            this.firstNativeAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }

          const repeatIndex =
            this.lastRepeatAdIndex === 0
              ? this.firstNativeAdIndex + recommendationsRepeat
              : recommendationsRepeat + this.lastRepeatAdIndex

          if (
            this.firstNativeAdIndex !== null &&
            recommendationsRepeat > 0 &&
            index === repeatIndex
          ) {
            this.lastRepeatAdIndex = index
            return (
              <BannerGrid data-page-name={page} data-ad-container="true">
                <AdHolder
                  page={pageAdConfig.page}
                  place="row"
                  type="native"
                  rowIndex={index}
                />
              </BannerGrid>
            )
          }
        }
      }
    }

    return null
  }

  /**
   * Function to display 1/4 native ad in grid - desktop
   * @param {*} index current row index
   * @param {*} articleIndex current article index in grid row
   * @param {*} rowType row type, whether odd or even
   * @param {*} isRecommendationRow whether the current row is recommendation section
   * @returns
   */
  getAdType = (index, isRecommendationRow) => {
    const { isMobile, pageAdConfig } = this.state
    const { mode } = this.props
    const isHome = mode === 'homepage'

    if (pageAdConfig && !isMobile) {
      const billBoard =
        pageAdConfig.billBoard && pageAdConfig.billBoard.length > 0
          ? sortBy(pageAdConfig.billBoard)
          : []
      // Article and Category page reduce index by 1 to match with home config
      const recommendations = pageAdConfig.recommendations
      const recommendationsRepeat = pageAdConfig.recommendationsRepeat

      // For home page recommendation, recommendations ad index should start from 0
      if (
        (isHome || mode === 'article') &&
        isRecommendationRow &&
        this.recommendationIndex
      ) {
        if (
          this.firstRecommendationNativeAdIndex === null &&
          recommendations >= 0 &&
          index === recommendations + (this.recommendationIndex - 1)
        ) {
          this.firstRecommendationNativeAdIndex = index
          return 'native'
        }

        const repeatIndexRecommendation =
          this.lastRepeatRecommendationAdIndex === 0
            ? this.firstRecommendationNativeAdIndex + recommendationsRepeat
            : recommendationsRepeat + this.lastRepeatRecommendationAdIndex

        if (
          this.firstRecommendationNativeAdIndex !== null &&
          recommendationsRepeat > 0 &&
          index === repeatIndexRecommendation
        ) {
          this.lastRepeatRecommendationAdIndex = index
          return 'native'
        }

        return null
      }

      // category page - check whether all the billboards are rendered
      if (
        billBoard.length > 0 &&
        !(billBoard.length === 1 && billBoard[0] === 0) &&
        index >= billBoard[billBoard.length - 1]
      ) {
        const lastBillBoardIndex = billBoard[billBoard.length - 1]

        const recomm =
          recommendations >= 1 ? recommendations - 1 : recommendations
        if (
          !isHome &&
          index === lastBillBoardIndex + recomm &&
          this.firstNativeAdIndex === null &&
          recommendations > 0
        ) {
          this.firstNativeAdIndex = index
          return 'native'
        }

        if (
          isHome &&
          index === lastBillBoardIndex + recomm &&
          this.firstNativeAdIndex === null &&
          recommendations > 0
        ) {
          this.firstNativeAdIndex = index
          return 'native'
        }

        const repeatIndex =
          this.lastRepeatAdIndex === 0
            ? this.firstNativeAdIndex + recommendationsRepeat
            : recommendationsRepeat + this.lastRepeatAdIndex

        // In case of VOD row Ad will not be shown, so adding ad in next row
        const prevIndex = index - 1
        if (
          isHome &&
          this.skippedAdInVodNow === prevIndex &&
          repeatIndex === prevIndex
        ) {
          return 'native'
        }

        if (
          this.firstNativeAdIndex !== null &&
          recommendationsRepeat > 0 &&
          index === repeatIndex
        ) {
          this.lastRepeatAdIndex = index
          return 'native'
        }
        // No billboard
      } else if (
        billBoard.length === 0 ||
        (billBoard.length === 1 && billBoard[0] === 0)
      ) {
        if (
          pageAdConfig.recommendations !== 0 &&
          index === recommendations - 1 &&
          this.firstNativeAdIndex === null &&
          recommendations > 0
        ) {
          this.firstNativeAdIndex = index
          return 'native'
        }

        const repeatIndex =
          this.lastRepeatAdIndex === 0
            ? this.firstNativeAdIndex + recommendationsRepeat
            : recommendationsRepeat + this.lastRepeatAdIndex

        if (
          this.firstNativeAdIndex !== null &&
          recommendationsRepeat > 0 &&
          index === repeatIndex
        ) {
          this.lastRepeatAdIndex = index
          return 'native'
        }
      }
    }

    return null
  }

  render() {
    const {
      mode,
      language,
      onScroll,
      gridResults,
      getCategoryUrl,
      fetchData,
      initializeVote,
      onSetDeleteArticleIds,
      deleteArticleIds,
      videoPlayingIdMobile,
      location,
      allowInfiniteScroll,
      subMode
    } = this.props

    const { page, isMobile, pageAdConfig } = this.state

    return (
      <React.Fragment>
        {this.renderTopAd(page, isMobile)}
        <InfiniteScroll
          key="gridScroll"
          onScroll={onScroll}
          dataLength={gridResults.data.length}
          next={() => {
            if (mode === 'homepage' && gridResults.page === 1) {
              return
            }

            if (gridResults.page > 0) {
              fetchData()
            }
          }}
          hasMore={
            mode === 'rearrange' || !allowInfiniteScroll
              ? false
              : gridResults.hasMore
          }
          loader={
            <GridLoader>
              <Spinner />
            </GridLoader>
          }
          style={{
            width: '100%',
            overflowY: 'hidden'
          }}
          endMessage={
            <GridLoader>
              {gridResults.data.length === 0 && <p>لايوجد معروضات</p>}
            </GridLoader>
          }
        >
          {gridResults.data.map((row, index) => {
            const rowData = row.isCategory ? row.data : row
            const hasVote = rowData.find((item) => item.vote) ? true : false
            const rowType = index % 2 === 0 ? 'odd' : 'even' // reverse naming as index starts with 0
            if (index === 0) {
              this.firstNativeAdIndex = null
              this.lastRepeatAdIndex = 0
              this.recommendationIndex = null
            }
            if (
              row.isRecommendation ||
              (this.recommendationIndex && index === this.recommendationIndex)
            ) {
              this.recommendationIndex = index
              this.lastRepeatRecommendationAdIndex = 0
              this.firstRecommendationNativeAdIndex = null
            }

            const isRecommendationRow = this.recommendationIndex
              ? index >= this.recommendationIndex
              : false

            const vodArticlesRow = !!row.isVodArticles
            const isBusinessArticlesRow = !!row.isBusinessArticles
            const isSportsArticlesRow = !!row.isSportsArticle

            if (vodArticlesRow) {
              this.skippedAdInVodNow = index
            }

            const isFullSet = rowData.length === 4
            const lastCellIndex = isFullSet ? 3 : rowData.length - 1
            const cellIndex = rowType === 'odd' ? lastCellIndex : 0

            // Check if coming row has Ad
            let adType = this.getAdType(index, isRecommendationRow)
            // if category row has 3 cell and ads need to be shown inside then duplicate one item for Ad
            if (rowData.length === 3 && row.isCategory && adType) {
              // clone cell
              let newCell =
                rowType === 'odd' ? rowData[rowData.length - 1] : rowData[0]

              if (newCell) {
                newCell = Object.assign({ extraCellForAd: true }, newCell)
                rowData.push(newCell)
              }
            }

            // do not show ad if less than 3 cards
            if (rowData.length < 3) {
              adType = null
            }

            return (
              <React.Fragment key={`row_${index}_${rowType}`}>
                {vodArticlesRow ||
                isBusinessArticlesRow ||
                isSportsArticlesRow ? (
                  <>
                    {isBusinessArticlesRow && (
                      <AsharqBusinessComponent
                        data={rowData}
                        isMobile={isMobile}
                        label={row.label}
                        isRearrange={mode === 'rearrange'}
                      />
                    )}
                    {vodArticlesRow && (
                      <VODCardContainer
                        data={rowData}
                        isMobile={isMobile}
                        isRearrange={mode === 'rearrange'}
                      />
                    )}
                    {isSportsArticlesRow && (
                      <SportsArticleCardContainer
                        data={rowData}
                        label={row.label}
                        isMobile={isMobile}
                        isRearrange={mode === 'rearrange'}
                      />
                    )}
                  </>
                ) : (
                  <CardsContainer
                    mode={mode}
                    data-page-name={page}
                    data-is-category={row.isCategory}
                    data-row-type={rowType}
                    data-row-index={index}
                    hasTopCategory={
                      index === 0 && row.isCategory && row.label && row.isFirst
                    }
                    isCategory={row.isCategory && row.isLast}
                    isRecommendation={row.isRecommendation}
                    isVideoRecommendation={row.isVideoRecommendation}
                    ignoreMarginTop={mode === 'livetv' || mode === 'article'}
                    rowHasVote={hasVote}
                  >
                    {rowData.map((article, articleIndex) => {
                      return (
                        <React.Fragment
                          key={`row-card${index}-${articleIndex}`}
                        >
                          {adType && cellIndex === articleIndex && !isMobile ? (
                            <>
                              {article.extraCellForAd ? (
                                <Card
                                  className="ad-cell"
                                  dataRowType={rowType}
                                  isCategory={row.isCategory && row.isLast}
                                  rowIndex={index}
                                  categoryLabel={row.label || row.linkTo}
                                  rowLength={rowData.length}
                                  rowHasLatestNews={
                                    rowData.find((item) => item.latestNews)
                                      ? true
                                      : false
                                  }
                                  disableDropBottom={
                                    article.latestNews &&
                                    gridResults.data.length >= 2 &&
                                    gridResults.data[1].data &&
                                    gridResults.data[1].data.length >= 4
                                  }
                                  isLatestNews={
                                    article.latestNews ? true : false
                                  }
                                  rowHasVote={rowData.find((item) => item.vote)}
                                  isVote={!!article.vote}
                                  article={article}
                                  index={articleIndex}
                                  language={language}
                                  data-template-number={article.templateNumber}
                                  key={`card_${articleIndex}_${rowType}_ads`}
                                  page={
                                    mode === 'search'
                                      ? 'searchpage'
                                      : 'homepage'
                                  }
                                  isRearrange={mode === 'rearrange'}
                                  initializeVote={initializeVote}
                                  setDeleteArticleIds={onSetDeleteArticleIds}
                                  deleteArticleIds={deleteArticleIds}
                                  videoPlayingIdMobile={videoPlayingIdMobile}
                                  mode={mode}
                                  subMode={subMode}
                                  adType={adType}
                                  pageAdConfig={pageAdConfig}
                                />
                              ) : (
                                <>
                                  <Card
                                    className="ad-cell"
                                    dataRowType={rowType}
                                    isCategory={row.isCategory && row.isLast}
                                    rowIndex={index}
                                    categoryLabel={row.label || row.linkTo}
                                    rowLength={rowData.length}
                                    rowHasLatestNews={
                                      rowData.find((item) => item.latestNews)
                                        ? true
                                        : false
                                    }
                                    disableDropBottom={
                                      article.latestNews &&
                                      gridResults.data.length >= 2 &&
                                      gridResults.data[1].data &&
                                      gridResults.data[1].data.length >= 4
                                    }
                                    isLatestNews={
                                      article.latestNews ? true : false
                                    }
                                    rowHasVote={rowData.find(
                                      (item) => item.vote
                                    )}
                                    isVote={!!article.vote}
                                    article={article}
                                    index={articleIndex}
                                    language={language}
                                    data-template-number={
                                      article.templateNumber
                                    }
                                    key={`card_${articleIndex}_${rowType}_ads`}
                                    page={
                                      mode === 'search'
                                        ? 'searchpage'
                                        : 'homepage'
                                    }
                                    isRearrange={mode === 'rearrange'}
                                    initializeVote={initializeVote}
                                    setDeleteArticleIds={onSetDeleteArticleIds}
                                    deleteArticleIds={deleteArticleIds}
                                    videoPlayingIdMobile={videoPlayingIdMobile}
                                    mode={mode}
                                    subMode={subMode}
                                    adType={adType}
                                    pageAdConfig={pageAdConfig}
                                  />
                                  <Card
                                    className="ad-fallback-cell"
                                    dataRowType={rowType}
                                    isCategory={row.isCategory && row.isLast}
                                    rowIndex={index}
                                    categoryLabel={row.label || row.linkTo}
                                    rowLength={rowData.length}
                                    rowHasLatestNews={
                                      rowData.find((item) => item.latestNews)
                                        ? true
                                        : false
                                    }
                                    disableDropBottom={
                                      article.latestNews &&
                                      gridResults.data.length >= 2 &&
                                      gridResults.data[1].data &&
                                      gridResults.data[1].data.length >= 4
                                    }
                                    isLatestNews={
                                      article.latestNews ? true : false
                                    }
                                    rowHasVote={rowData.find(
                                      (item) => item.vote
                                    )}
                                    isVote={!!article.vote}
                                    article={article}
                                    index={articleIndex}
                                    language={language}
                                    data-template-number={
                                      article.templateNumber
                                    }
                                    key={`card_${articleIndex}_${rowType}`}
                                    page={
                                      mode === 'search'
                                        ? 'searchpage'
                                        : 'homepage'
                                    }
                                    isRearrange={mode === 'rearrange'}
                                    initializeVote={initializeVote}
                                    setDeleteArticleIds={onSetDeleteArticleIds}
                                    deleteArticleIds={deleteArticleIds}
                                    videoPlayingIdMobile={videoPlayingIdMobile}
                                    mode={mode}
                                    subMode={subMode}
                                  />
                                </>
                              )}
                            </>
                          ) : (
                            <Card
                              dataRowType={rowType}
                              isCategory={row.isCategory && row.isLast}
                              rowIndex={index}
                              categoryLabel={row.label || row.linkTo}
                              rowLength={rowData.length}
                              rowHasLatestNews={
                                rowData.find((item) => item.latestNews)
                                  ? true
                                  : false
                              }
                              disableDropBottom={
                                article.latestNews &&
                                gridResults.data.length >= 2 &&
                                gridResults.data[1].data &&
                                gridResults.data[1].data.length >= 4
                              }
                              isLatestNews={article.latestNews ? true : false}
                              rowHasVote={rowData.find((item) => item.vote)}
                              isVote={!!article.vote}
                              article={article}
                              index={articleIndex}
                              language={language}
                              data-template-number={article.templateNumber}
                              key={`card_${articleIndex}_${rowType}`}
                              page={
                                mode === 'search' ? 'searchpage' : 'homepage'
                              }
                              isRearrange={mode === 'rearrange'}
                              initializeVote={initializeVote}
                              setDeleteArticleIds={onSetDeleteArticleIds}
                              deleteArticleIds={deleteArticleIds}
                              videoPlayingIdMobile={videoPlayingIdMobile}
                              mode={mode}
                              subMode={subMode}
                            />
                          )}
                        </React.Fragment>
                      )
                    })}
                    {row.isCategory && row.label && row.isFirst && (
                      <>
                        {mode === 'homepage' &&
                        row.isRecommendation &&
                        mode !== 'livetv' ? (
                          <CategoryFooter id="footer">
                            <Footer />
                          </CategoryFooter>
                        ) : null}
                        <CategoryLabel
                          isRecommendation={row.isRecommendation}
                          isLinkable={row.isLinkable}
                        >
                          {!row.isLinkable ? (
                            <div className="notLinkable">{row.label}</div>
                          ) : (
                            <Link
                              className="linkable"
                              to={encodeURI(getCategoryUrl(row))}
                            >
                              {row.label}
                              <ImgContainer>
                                <CategoryLabelImg
                                  className="whiteArrow"
                                  id="arrow"
                                  src={whiteArrow}
                                  alt="labelArrow"
                                />
                                <CategoryLabelImg
                                  className="redArrow"
                                  id="arrow"
                                  src={redArrow}
                                  alt="labelArrow"
                                />
                              </ImgContainer>
                            </Link>
                          )}
                        </CategoryLabel>
                      </>
                    )}
                  </CardsContainer>
                )}
                {this.renderCategoryAd(
                  index,
                  row,
                  rowType,
                  isRecommendationRow
                )}
              </React.Fragment>
            )
          })}
        </InfiniteScroll>
      </React.Fragment>
    )
  }
}
