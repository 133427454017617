export default (elem, offset = {}) => {
  const bounding = elem.getBoundingClientRect()

  let out = {}
  out.top = bounding.top < (offset.top || 0)
  out.left = bounding.left < (offset.left || 0)
  out.bottom =
    bounding.bottom >
    (window.innerHeight || document.documentElement.clientHeight)
  out.right =
    bounding.right > (window.innerWidth || document.documentElement.clientWidth)

  out.any = out.top || out.left || out.bottom || out.right
  out.all = out.top && out.left && out.bottom && out.right

  return out
}
