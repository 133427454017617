import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

const Vote = ({ voteConfig = {}, fetchVoteConfig = () => {}, style = {} }) => {
  useEffect(() => {
    fetchVoteConfig()
  }, [])

  const iframeStyle = !isEmpty(
    voteConfig.data && voteConfig.data.length && voteConfig.data[0].cssStyle
  )
    ? voteConfig.data[0].cssStyle
    : {
        width: '100%',
        height: '100%'
      }

  return (
    <div className="scroll-wrapper" style={style}>
      {voteConfig.fetched ? (
        <iframe
          id="MegaControllerIframe-1590050011560"
          style={iframeStyle}
          src={voteConfig.data.length ? voteConfig.data[0].url : ''}
        ></iframe>
      ) : null}
    </div>
  )
}

const mapStateToProps = ({ voteConfig }) => {
  return {
    voteConfig
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVoteConfig: () => {
      dispatch({ type: 'VOTE_MEGAPHONE_REQUEST' })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vote)
