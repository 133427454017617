import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import React from 'react'
import { Grid } from './grid'

const DragAndDrop = props => {
  return (
    <DndProvider backend={HTML5Backend}>
      <Grid {...props} />
    </DndProvider>
  )
}

export default DragAndDrop
